<template>
  <v-app>
    <!-- <div class="helper-firebase-ui"></div> -->
    <v-row class="my-auto">
      <v-col class="colsl pa-6 pa-sm-2 pa-md-8 pb-sm-0 pb-md-0" cols="12" sm="6" md="6">
        <img src="@/assets/wyselylogo.svg" class="pl-0 pl-sm-8 pl-md-8 pt-sm-2 pt-md-2" />

        <div class="cols2txtmbl">
          <img style="color: white" src="@/assets/image 2m.svg" width="100%" />
        </div>

        <div class="
            text-center
            ma-4
            pa-sm-0 pa-md-12
            mx-sm-8 mx-md-12
            pb-sm-10 pb-md-10
            mb-sm-6 mb-md-6
          ">
          <p class="title pb-6">Sign in your account using with Google</p>
          <!-- <p class="title pb-6">
            Sign in your account using with Google or Facebook
          </p> -->
          <v-btn @click="googleSignIn" block large class="btn white rounded-lg"><img class="pa-2"
              src="@/assets/google.svg" /><span><b>Sign in with Google</b></span></v-btn><br />
          <v-btn @click="facebookSignIn" block large class="btn white mb-12 rounded-lg"><img class="pa-2"
              src="@/assets/facebook.svg" /><span><b>Sign in with Facebook</b></span></v-btn>
          <p style="color: #858b95" class="mt-8">
            By continuing with Google, you agree our <a style="text-decoration: none;" href="/terms">Terms</a> and
            <a style="text-decoration: none;" href="/privacy">Privacy Policy.</a>
          </p>
          <!-- <p style="color: #858b95" class="mt-8">
            By continuing with Google or Facebook, you agree our Terms and
            Privacy Policy.
          </p> -->
        </div>

        <!-- <div class="text-center ma-0 lpbtmctn">
          <v-divider></v-divider>
          <p class="mt-6 mb-0">Don't have an account? <v-btn class="pa-0" text
              style="color: #3B82F6; text-decoration: none; " to="/Dashboard">Sign
              up</v-btn>
          </p>
        </div> -->
      </v-col>

      <v-col class="cols2 pa-6" cols="12" sm="6" md="6">
        <div class="cols2img"></div>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
//  import AES from 'AES';
import { apiurl } from "../apiUrl";
import axios from "axios";
import Vue from "vue";
import VueCryptojs from "vue-cryptojs";
// import * as firebaseui from 'firebaseui'
// import firebase from 'firebase/app';

Vue.use(VueCryptojs);
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider,
  // fetchSignInMethodsForEmail,
  // signOut,
} from "firebase/auth";
// import { log } from "console";
export default {
  data() {
    return {
      $hostname: "",
      $signinusername: "",
      secret: "12345678",
    };
  },
  methods: {
    getTokenDetials() {
      var data = '';
      var config = {
        method: 'post',
        url: `${apiurl}/getToken`,
        headers: {},
        data: data
      };

      axios(config)
        .then(function (response) {
          // console.log(JSON.stringify("getTokendata", response.data));
          console.log("getTokendata", response.data.Token)
          localStorage.setItem(
            "getTokendata",
            response.data.Token
          );
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    checkClientSync(email) {
      var gauthclientseassion = localStorage.clientsessionstore;
      console.warn("&&&-WoRKING",email,gauthclientseassion);
      var axiosthis = this;
      var data = JSON.stringify({
        email: email,
      });

      var config = {
        method: "post",
        url: `${apiurl}/syncEmailDetails`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': gauthclientseassion
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          // console.warn("&&&--sync",response, axiosthis.emailDatafetch);
          axiosthis.emailDatafetch = response.data.Data;
          console.warn("&&&", axiosthis.emailDatafetch[0].clientID);

          localStorage.setItem(
            "client-id",
            axiosthis.emailDatafetch[0].clientID
          );
          // axiosthis.dspemailaccount = axiosthis.emailDatafetch.Data;
          // if (response.data.msg == "No Email Sync data available for User") {
          //     // axiosthis.dspnoaccounts = false;
          //     // axiosthis.emailfetchdialog = false;
          //     // axiosthis.emailadddialog = false;
          //     axiosthis.dspnoaccounts = true
          //     axiosthis.emailfetchdialog = true;

          // }
          // if (axiosthis.dspemailaccount.length >= 5) {
          //     axiosthis.btnaddacc = true;
          // }
          console.log("dspemailaccount", axiosthis.dspemailaccount);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    googleSignIn: function () {
      // const provider = new GoogleAuthProvider();
      //       let ui = new firebaseui.auth.AuthUI(firebase.auth())
      //       let uiConfig = {
      //     signInOptions: [
      //         {
      //             provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      //             // Required to enable ID token credentials for this provider.
      //             // This can be obtained from the Credentials page of the Google APIs
      //             // console. Use the same OAuth client ID used for the Google provider
      //             // configured with GCIP or Firebase Auth.
      //             clientId: '55922781779-fcl6vdi2327c5i82287vnr004dh91qtm.apps.googleusercontent.com',
      //             customParameters: {
      //                 // Forces account selection even when one account
      //                 // is available.
      //                 prompt: 'select_account',
      //             },
      //         }
      //     ],
      //     // Enable one-tap sign-in.
      //     credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO
      // };

      // ui.start(".helper-firebase-ui", uiConfig);

      const provider = new GoogleAuthProvider();
      provider.addScope("profile");
      provider.addScope("email");
      // provider.addCredentialHelper('firebaseui.auth.CredentialHelper.GOOGLE_YOLO')
      // console.log("provider",provider)

      provider.setCustomParameters({
        // 'credentialHelper': "firebaseui.auth.CredentialHelper.GOOGLE_YOLO",
        prompt: "select_account",
      });
      signInWithPopup(getAuth(), provider)
        .then((result) => {
          console.log("Logged In", result);
          this.google = true;
          this.getTokenDetials();
          var axiosthis = this;

          var data = JSON.stringify({
            UserName: result._tokenResponse.fullName,
            Email: result._tokenResponse.email,
            Provider: result.providerId,
            SessionId: result.user.accessToken,
          });
          console.log("result acess token", data);
          //signinuserename encrept&decryted start
          var encryptedsignname = this.$CryptoJS.AES.encrypt(
            result._tokenResponse.fullName,
            "Secret Passphrase"
          ).toString();
          console.log("encryptedText", encryptedsignname);
          localStorage.storedData = encryptedsignname;
          console.log("encript local stroage", localStorage.storedData);
          var localStoragestorename = localStorage.storedData;
          var decryptedname = this.$CryptoJS.AES.decrypt(
            localStoragestorename,
            "Secret Passphrase"
          ).toString(this.$CryptoJS.enc.Utf8);
          console.log("decrypted store value", decryptedname);
          localStorage.decryptedstoredname = decryptedname;
          console.log(
            "decrypted local stroage store value",
            localStorage.decryptedstoredname
          );
          // signinusername  end
          // signinuseremailid encrept&decryted start
          var encryptedText = this.$CryptoJS.AES.encrypt(
            result._tokenResponse.email,
            "Secret Passphrase"
          ).toString();
          console.log("encryptedText", encryptedText);
          localStorage.storedData = encryptedText;
          console.log("encript local stroage", localStorage.storedData);
          var localStoragestore = localStorage.storedData;
          var decryptedText = this.$CryptoJS.AES.decrypt(
            localStoragestore,
            "Secret Passphrase"
          ).toString(this.$CryptoJS.enc.Utf8);
          console.log("decrypted store value", decryptedText);
          localStorage.decryptedstoredData = decryptedText;
          console.log(
            "decrypted local stroage store value",
            localStorage.decryptedstoredData
          );
          // signinuseremail id end

          //  global variable declare signin usename&emailid
          (Vue.prototype.$hostname = result._tokenResponse.email),
            console.log("globalvariablename", this.$hostname);
          (Vue.prototype.$signinusername = result._tokenResponse.fullName),
            console.log("signin user fullname", this.$signinusername);
          // global variable end
          var config = {
            method: "post",
            url: `${apiurl}/saveuser`,
            headers: {
              "Content-Type": "application/json",
              // 'Authorization': 'Bearer ' +localstroageget
            },
            data: data,
          };
          console.log("check header value", config);
          axios(config)
            .then(function (response) {
              // console.log(JSON.stringify(response.data));
              console.log("response value change", response.data);
              console.log("client seassion", response.data.clientsession);
              localStorage.clientsessionstore = response.data.clientsession;
              axiosthis.checkClientSync(result._tokenResponse.email);

              console.log(
                "localStorage clientsession",
                localStorage.clientsessionstore
              );
            })
            .catch(function (error) {
              console.log(error);
            });
            setTimeout(function(){
          var datas = JSON.stringify({
            Email: result._tokenResponse.email,
          });
          console.log("datas", datas);
          var localstroageget = localStorage.clientsessionstore;
          console.log("localstroage get value", localstroageget);
          var configs = {
            method: "post",
            url: `${apiurl}/getuserdetailstatus`,
            headers: {
              "Content-Type": "application/json",
              Authorization: localstroageget,
            },
            data: datas,
          };

          console.log("header get local stroage value", configs);
          axios(configs)
            .then(function (response) {
              // console.log(JSON.stringify(response.data));
              console.log("get user detail status response", response);
              // console.log("getuser detail status",response.statusText);

              if (response.data.userstatus == "Page2") {
                console.warn("&&&-axiosthis.emailDatafetch",axiosthis.emailDatafetch);

                if (axiosthis.emailDatafetch) {
                  axiosthis.$router.push("/Dashboard");
                } else {
                  // axiosthis.$router.push('Accounts')
                  axiosthis.$router.push("/Accounts");
                }
              }
              if (response.data.userstatus == "User Already Available") {
                if (axiosthis.emailDatafetch) {
                  axiosthis.$router.push("/Dashboard");
                } else {
                  // axiosthis.$router.push('Accounts')
                  axiosthis.$router.push("/Accounts");
                }
              }
              if (response.data.userstatus == "User not available") {
                axiosthis.$router.push("/welcome");
              }
              if (response.data.userstatus == "Page1") {
                // preferencetwo
                axiosthis.$router.push("/preferencetwo");
              }
            })
            .catch(function (error) {
              console.log(error);
            });
            }, 1000);
       
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    facebookSignIn: function () {
      const provider = new FacebookAuthProvider();
      provider.setCustomParameters({
        prompt: "select_account",
      });
      signInWithPopup(getAuth(), provider).then((result) => {
        console.log("Logged In ", result);
        this.facebook = true;
        // this.$router.push('landing')
        this.checkClientSync(result._tokenResponse.email);
        var data = JSON.stringify({
          UserName: result._tokenResponse.fullName,
          Email: result._tokenResponse.email,
          Provider: result.providerId,
          IdToken: result._tokenResponse.idToken,
          SessionId: result.user.accessToken,
        });
        console.log("result acess token", data);
        //signinuserename encrept&decryted start
        var encryptedsignname = this.$CryptoJS.AES.encrypt(
          result._tokenResponse.fullName,
          "Secret Passphrase"
        ).toString();
        console.log("encryptedText", encryptedsignname);
        localStorage.storedData = encryptedsignname;
        console.log("encript local stroage", localStorage.storedData);
        var localStoragestorename = localStorage.storedData;
        var decryptedname = this.$CryptoJS.AES.decrypt(
          localStoragestorename,
          "Secret Passphrase"
        ).toString(this.$CryptoJS.enc.Utf8);
        console.log("decrypted store value", decryptedname);
        localStorage.decryptedstoredname = decryptedname;
        console.log(
          "decrypted local stroage store value",
          localStorage.decryptedstoredname
        );
        // signinusername  end
        // signinuseremailid encrept&decryted start
        var encryptedText = this.$CryptoJS.AES.encrypt(
          result._tokenResponse.email,
          "Secret Passphrase"
        ).toString();
        console.log("encryptedText", encryptedText);
        localStorage.storedData = encryptedText;
        console.log("encript local stroage", localStorage.storedData);
        var localStoragestore = localStorage.storedData;
        var decryptedText = this.$CryptoJS.AES.decrypt(
          localStoragestore,
          "Secret Passphrase"
        ).toString(this.$CryptoJS.enc.Utf8);
        console.log("decrypted store value", decryptedText);
        localStorage.decryptedstoredData = decryptedText;
        console.log(
          "decrypted local stroage store value",
          localStorage.decryptedstoredData
        );
        // signinuseremail id end

        //  global variable declare signin usename&emailid
        (Vue.prototype.$hostname = result._tokenResponse.email),
          console.log("globalvariablename", this.$hostname);
        (Vue.prototype.$signinusername = result._tokenResponse.fullName),
          console.log("signin user fullname", this.$signinusername);
        // global variable end
        var config = {
          method: "post",
          url: `${apiurl}/saveuser`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        console.log("check header value", config);
        axios(config)
          .then(function (response) {
            // console.log(JSON.stringify(response.data));
            console.log("Facebook signin response", response);
            console.log("Facebook signin response.data", response.data);
            console.log("client seassion", response.data.clientsession);
            localStorage.clientsessionstore = response.data.clientsession;
            console.log(
              "localStorage clientsession",
              localStorage.clientsessionstore
            );
          })
          .catch(function (error) {
            console.log(error);
          });

        var axiosthis = this;
        var datas = JSON.stringify({
          Email: result._tokenResponse.email,
        });


        console.log("fb getuser email", datas);
        var localstroageget = localStorage.clientsessionstore;
        console.log("localstroage get value", localstroageget);
        var configs = {
          method: "post",
          url: `${apiurl}/getuserdetailstatus`,
          headers: {
            "Content-Type": "application/json",
            Authorization: localstroageget,
          },
          data: datas,
        };

        console.log("header get local stroage value", configs);
        axios(configs)
          .then(function (response) {
            // console.log(JSON.stringify(response.data));
            console.log("get user detail status response", response);
            // console.log("getuser detail status",response.statusText);

            if (response.data.userstatus == "Page2") {
              if (axiosthis.emailDatafetch) {
                axiosthis.$router.push("/Dashboard");
              } else {
                // axiosthis.$router.push('Accounts')
                axiosthis.$router.push("/Accounts");
              }
            }
            if (response.data.userstatus == "User Already Available") {
              if (axiosthis.emailDatafetch.clientID) {
                axiosthis.$router.push("/Dashboard");
              } else {
                // axiosthis.$router.push('Accounts')
                axiosthis.$router.push("/Accounts");
              }
            }
            if (response.data.userstatus == "User not available") {
              axiosthis.$router.push("/welcome");
            }
            if (response.data.userstatus == "Page1") {
              // preferencetwo
              axiosthis.$router.push("/preferencetwo");
            }
          })
          .catch(function (error) {
            console.log(error);
          });


      });
    },
  },

  // mounted() {
  //   // var axios = require('axios');
  //   var data = '';

  //   var config = {
  //     method: 'post',
  //     url: `${apiurl}/getToken`,
  //     headers: {},
  //     data: data
  //   };

  //   axios(config)
  //     .then(function (response) {
  //       // console.log(JSON.stringify("getTokendata", response.data));
  //       console.log("getTokendata", response.data.Token)
  //       localStorage.setItem(
  //         "getTokendata",
  //         response.data.Token
  //       );
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });

  // }
};
</script>

<style>
.v-application,
.v-application .display-1,
.v-application .headline,
.v-application .title,
.v-application .subtitle-1,
.v-application .subtitle-2,
.v-application .body-1,
.v-application .body-2,
.v-application .caption,
.v-application .overline {
  font-family: "DM Sans", sans-serif !important;
}

.colsl {
  background-color: #f9fbfc !important;
}

.cols2img {
  height: 100% !important;
  background-image: url(@/assets/image\ 2m.svg) !important;
  background-color: white !important;
  background-size: 100% !important;
}

.cols2txt {
  position: absolute;
  bottom: 15% !important;
  font-weight: 500 !important;
}

.lpbtmctn {
  position: absolute;
  bottom: 0 !important;
  width: 45% !important;
}

.cols2txtmbl {
    display: none !important;
}

@media only screen and (max-width: 600px) {
  .colsl {
    background-color: white !important;
    text-align: center;
  }

  .cols2txtmbl {
    display: contents !important;
  }

  .cols2 {
      display: none !important;
  }

  .cols2txt {
    position: static;
    bottom: none !important;
  }

  .lpbtmctn {
    position: absolute;
    bottom: 0 !important;
    width: 100% !important;
  }

  .btn {
    box-shadow: 0px 8px 16px -4px rgba(22, 34, 51, 0.08) !important;
  }
}
</style>