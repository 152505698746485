import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { initializeApp } from "firebase/app";



const firebaseConfig = {
  apiKey: "AIzaSyC9WZkncGsa52xKwhZbfLQS3kKGiQ4HOWs",
  authDomain: "signin-226ac.firebaseapp.com",
  projectId: "signin-226ac",
  storageBucket: "signin-226ac.appspot.com",
  messagingSenderId: "55922781779",
  appId: "1:55922781779:web:bbfb7a4c07a15fa5e3a0a6"
};

initializeApp(firebaseConfig);
Vue.config.productionTip = false

new Vue({
   created: function() {
    console.log(this.$hostname)
    console.log(this.$signinusername)
   
  },
  
 
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
