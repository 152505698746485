<template>
  <v-app>
    <div>
      <router-view/>
    </div>
  </v-app>
</template>

<script>

export default {
    name: "App",
    data: () => ({
    //
    }),
};
</script>