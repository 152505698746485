<template>
    <div>
        <v-data-table :loading="printsumgetloader" mobile-breakpoint fixed-header disable-sort :headers="cliidhead"
            :items="tableDataFinal" item-key="FullName" hide-default-footer style="border: 1px solid #efeef3"
            class="cliidsty elevation-0 rounded-lg" :page.sync="page" :items-per-page="10"
            @page-count="pageCount = $event" group-by="Client_Code">
            <template v-slot:[`footer`]>
                <v-divider></v-divider>
                <div class=" text-center py-2">
                    <v-pagination v-model="page" :length="pageCount"></v-pagination>
                </div>
            </template>
            <template v-slot:[`item.FullName`]="{ item }">
                {{ item.FullName.match(/\((.*?)\)/)[1] }}
            </template>
            <template v-slot:[`group.header`]="{ group, items }">
                <th>
                    <div v-if="group.slice(0, 1) == 'Z'">
                        <img width="26px" class="pa-1 rounded" src="@/assets/Ma_zebu.svg">

                        <!-- <span class="mb-0 subtitle-1 font-weight-bold">ZEBU</span> -->
                    </div>
                    <div v-else>
                        <span class="mb-0 subtitle-1 font-weight-bold">{{ group }}</span>
                    </div>
                </th>
                <td></td>
                <td></td>
                <td></td>
                <td class="text-right">{{ subTotal(items, 'Net_Value') }}</td>
                <td class="text-right">{{ subTotal(items, 'BuyOrSell') }}</td>
                <td class="text-right">{{ subTotal(items, 'Exchange') }}</td>
                <td class="text-right">{{ subTotal(items, 'MarketCap') }}</td>
            </template>
            <template v-slot:[`item.MarketCap`]="{ item }">
                <span style="color: #34a853" v-if="item.MarketCap > 0">+{{ item.MarketCap }}</span>
                <span style="color: #e12626" v-else>{{
                        item.MarketCap
                }}</span>
            </template>
            <template v-slot:[`item.Exchange`]="{ item }">
                <span style="color: #34a853" v-if="item.Exchange > 0">{{
                        item.Exchange
                }}</span>
                <span style="color: #e12626" v-else>{{
                        item.Exchange
                }}</span>
            </template>
            <template v-if="usertotaldata" v-slot:[`body.append`]>
                <tr class="black--text">
                    <td class="text-left">
                        <span class="subtitle-1 font-weight-medium pa-1 rounded mr-1"
                            style="background-color: #e8ebed; color: #505359">{{ clientids.length }}
                            accounts</span>

                        <span class="subtitle-1 font-weight-medium pa-1 rounded"
                            style="background-color: #e8ebed; color: #505359">{{ tableDataFinal.length }}
                            holdings</span>
                    </td>
                    <td>{{ totals.none }}</td>
                    <td>{{ totals.none }}</td>
                    <td>{{ totals.none }}</td>
                    <td class="subtitle-1 text-right font-weight-medium">
                        {{ Math.abs(totals.Net_Value).toFixed(2) }}
                    </td>
                    <td class="subtitle-1 text-right font-weight-medium">
                        {{ totals.BuyOrSell }}
                    </td>
                    <td class="subtitle-1 text-right font-weight-medium">
                        <span v-if="totals.Exchange == 0">0.00</span>
                        <span v-else>
                            <span class="font-weight-medium pa-1 rounded" v-if="totals.Exchange > 0"
                                style="background-color: #eeffed; color: #34a853">{{ totals.Exchange }}</span>
                            <span class="font-weight-medium pa-1 rounded" v-else
                                style="background-color: #ffeeee; color: #e12626">{{ totals.Exchange }}</span>
                        </span>
                    </td>
                    <td class="subtitle-1 text-right font-weight-medium">
                        <span v-if="totals.Exchange == 0">0.00%</span>
                        <span v-else>
                            <span class="font-weight-medium pa-1 rounded" v-if="totals.Exchange > 0"
                                style="background-color: #eeffed; color: #34a853">+{{
                                        (
                                            (totals.Exchange / totals.Net_Value) *
                                            100
                                        ).toFixed(2)
                                }}%</span>
                            <span class="font-weight-medium pa-1 rounded" v-else
                                style="background-color: #ffeeee; color: #e12626">{{
                                        (
                                            (totals.Exchange / totals.Net_Value) *
                                            100
                                        ).toFixed(2)
                                }}%</span>
                        </span>
                    </td>
                </tr>
            </template>
            <template v-if="usernodata" v-slot:no-data>
                <v-col class="mx-auto pa-15">
                    <v-img class="align-self-stretch mx-auto" width="100px"
                        :src="require('@/assets/no data folder.svg')" />
                    <h4>No data available</h4>
                </v-col>
            </template>
        </v-data-table>
    </div>
</template>
  
<!-- <script>
import { apiurl } from "../../apiUrl";

import axios from "axios";
export default {
    data() {
        return {
            page: 1,
            pageCount: 0,

            usertotaldata: true,
            usernodata: false,
            holdingdetails: [],
            sumget: [],
            dataget: [],
            FullName: "",
            printsumget: [],
            printsumgetloader: true,
            tokengetdata: [],
            tableDataFinal: [],
        };
    },

    mounted() {
        //var userBrokerClientId = localStorage.getItem("client-id");
        var gauthclientseassion = localStorage.clientsessionstore
        var getTokendata = localStorage.getItem("getTokendata")

        var userBrokerClientId = ["ZVK0106", "ZE1A57", "ZE1A40"]
        var axiosThis = this;

        for (var d = 0; d < userBrokerClientId.length; d++) {
            var clientIds = userBrokerClientId[d];
            console.log("clientIds", clientIds)

            var data = JSON.stringify({
                clientid: clientIds,
            });

            var config = {
                method: "post",
                url: `${apiurl}/holdingDetails`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': gauthclientseassion
                },
                data: data,
            };

            axios(config)
                .then(function (response) {
                    console.log("response", response);

                    axiosThis.holdingdetails = response.data.data;
                    console.log("response", response.data.data);
                    if (response.data.msg == "No Data Available") {
                        axiosThis.usernodata = true;
                        axiosThis.printsumgetloader = false;
                        axiosThis.usertotaldata = false;
                    }
                    console.log("holdingdetails", axiosThis.holdingdetails);
                    for (var i = 0; i < axiosThis.holdingdetails.length; i++) {
                        axiosThis.sumget.push(axiosThis.holdingdetails[i].Summary);
                    }
                    // console.log("sumget--1", axiosThis.sumget);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }

        setTimeout(function () {
            // var axiosThis = this
            for (var l = 0; l < axiosThis.sumget.length; l++) {
                var sumdetails = axiosThis.sumget[l];
                console.log(l, "sumget--1", axiosThis.sumget[l].FullName);

                if (sumdetails.Quantity > 0) {
                    // console.log("sumget--1",l, axiosThis.sumget[l].FullName);

                    // console.log("sundd", sumdetails);
                    sumdetails.Avg_Price = Math.abs(sumdetails.Avg_Price.toFixed(2));
                    sumdetails.Net_Value = Math.abs(sumdetails.Net_Value.toFixed(2));

                    // console.log("tokk", axiosThis.tokengetdata);
                    axiosThis.printsumget.push(sumdetails);
                    var dataa = JSON.stringify({
                        userId: "ZP00172",
                        scripList: "nse_cm|212,nse_cm|" + sumdetails.token,
                    });

                    var tokenconfig = {
                        method: "post",
                        url: "https://www.zebull.in/rest/MobullService/api/marketWatch/scripsMW",
                        headers: {
                            Authorization: `Bearer ZP00172 ${getTokendata}`,
                            "Content-Type": "application/json",
                        },
                        data: dataa,
                    };

                    axios(tokenconfig)
                        .then(function (response) {
                            console.log("MARKETwatch", response.data[1]);
                            axiosThis.tokengetdata.push(response.data[1]);
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                } if (sumdetails.Quantity == 0) {
                    axiosThis.usernodata = true;
                    axiosThis.printsumgetloader = false;
                }
            }
            // console.log("printsumget", axiosThis.printsumget);
            setTimeout(function () {

                for (var o = 0; o < axiosThis.printsumget.length; o++) {
                    console.log("loc", axiosThis.printsumget, "settimeout", axiosThis.tokengetdata)

                    for (var w = 0; w < axiosThis.printsumget.length; w++) {
                        console.log("|", w);
                        if (
                            axiosThis.printsumget[o].Symbol ==
                            axiosThis.tokengetdata[w].symbolname && axiosThis.printsumget[o].Client_Code == axiosThis.printsumget[w].Client_Code
                        ) {
                            console.log(
                                axiosThis.printsumget[o].Symbol,
                                "|",
                                axiosThis.tokengetdata[w].symbolname
                            );

                            axiosThis.printsumget[o].sector = Number(
                                axiosThis.tokengetdata[w].ltp
                            );
                            axiosThis.printsumget[o].BuyOrSell = Number(
                                (
                                    axiosThis.printsumget[o].Quantity *
                                    axiosThis.printsumget[o].sector
                                ).toFixed(2)
                            );
                            axiosThis.printsumget[o].Exchange = Number(
                                (
                                    axiosThis.printsumget[o].BuyOrSell -
                                    axiosThis.printsumget[o].Net_Value
                                ).toFixed(2)
                            );
                            axiosThis.printsumget[o].MarketCap = Number(
                                (
                                    (axiosThis.printsumget[o].Exchange /
                                        axiosThis.printsumget[o].Net_Value) *
                                    100
                                ).toFixed(2)
                            );
                            axiosThis.printsumget[o]["close"] = Number(
                                axiosThis.tokengetdata[w].close
                            );
                            axiosThis.printsumget[o]["pnlval"] = (axiosThis.printsumget[o].sector - axiosThis.tokengetdata[w].close) * axiosThis.printsumget[o].Quantity;
                            //   // console.log(axiosThis.tokengetdata[o], "tokengetdata")
                            axiosThis.tableDataFinal.push(axiosThis.printsumget[o]);
                            if (axiosThis.tableDataFinal.length > 0) {
                                axiosThis.printsumgetloader = false;
                            }
                            console.log(axiosThis.tableDataFinal[o], "tokengetdata")

                        }
                    }
                }
            }, 2000);

            console.log("printsumget", axiosThis.printsumget);

            //code goes here
        }, 5000);
    },
    computed: {
        totals() {
            const totals = this.tableDataFinal.reduce(
                (acc, d) => {
                    acc.Quantity += d.Quantity;
                    acc.Avg_Price += d.Avg_Price;
                    acc.Net_Value += d.Net_Value;
                    acc.sector += d.sector;
                    acc.close += d.close;
                    acc.BuyOrSell += d.BuyOrSell;
                    acc.Exchange += d.Exchange;
                    acc.pnlval += d.pnlval;
                    return acc;
                },
                {
                    Quantity: 0,
                    Avg_Price: 0.00,
                    Net_Value: 0.00,
                    sector: 0.00,
                    BuyOrSell: 0.00,
                    Exchange: 0.00,
                    pnlval: 0.00,
                    none: "",
                }
            );
            totals.Avg_Price = totals.Avg_Price.toFixed(2);
            totals.Net_Value = totals.Net_Value.toFixed(2);
            totals.sector = totals.sector.toFixed(2);
            totals.BuyOrSell = totals.BuyOrSell.toFixed(2);
            totals.Exchange = totals.Exchange.toFixed(2);
            totals.pnlval = totals.pnlval.toFixed(2);
            return totals;
        },
        cliidhead() {
            return [
                {
                    text: "Company",
                    value: "FullName",
                    align: "left",
                    filter: (f) => {
                        return (f + "")
                            .toUpperCase()
                            .includes(this["FullName"].toUpperCase());
                    },
                },
                { text: "Qty", value: "Quantity", align: "right" },
                { text: "Buy avg.", value: "Avg_Price", align: "right" },
                { text: "LTP", value: "sector", align: "right" },
                { text: "Buy value", value: "Net_Value", align: "right" },
                { text: "Present value", value: "BuyOrSell", align: "right" },
                { text: "P&L", value: "Exchange", align: "right" },
                { text: "P&L change", value: "MarketCap", align: "right" },
                // { text: "close", value: "close", align: "right" },
                // { text: "ltp-close*qty", value: "over", align: "right" },
                // { text: "pnlval", value: "pnlval", align: "right" },
            ];
        },
    },
    methods: {
        subTotal(items, column) {
            console.warn(items, column);
            return items.reduce((sum, item) => sum + item[column], 0);
        },
        groupBycli() {
            this.groupBy = 'Client_Code';
        },
    },
};
</script> -->
<script>
import { apiurl } from "../../apiUrl";

import axios from "axios";
export default {
    data() {
        return {
            page: 1,
            pageCount: 0,
            usertotaldata: true,
            usernodata: false,
            clientids: [],
            holdingdetails: [],
            sumget: [],
            dataget: [],
            FullName: "",
            clsval: [],
            closepnl: 0,
            printsumget: [],
            printsumgetloader: true,
            tokengetdata: [],
            tableDataFinal: [],
            tradedetails: [],
            emailDatafetch: []
        };
    },
    methods: {
        checkClientSync() {
            var gauthclientseassion = localStorage.clientsessionstore
            // var gauthclientseassion = localStorage.clientsessionstore
            var getTokendata = localStorage.getItem("getTokendata")
            var email = localStorage.getItem('decryptedstoredData')
            var data = JSON.stringify({
                email: email,
            });

            var config = {
                method: "post",
                url: `${apiurl}/syncEmailDetails`,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': gauthclientseassion
                },
                data: data,
            };
            var axiosThis = this
            var axiosthis = this
            axios(config)
                .then(function (response) {
                    console.warn("&&&e", response);
                    axiosthis.emailDatafetch = response.data.Data;
                    console.log("clientIds", response.data.msg)
                    if (response.data.msg == 'No Email Sync data available for User') {
                        axiosthis.printsumgetloader = false;
                        axiosthis.usernodata = true;
                    }
                    for (var d = 0; d < axiosthis.emailDatafetch.length; d++) {
                        var clientIds = axiosthis.emailDatafetch[d].clientID;
                        console.log("clientIds", clientIds)
                        axiosthis.clientids.push(axiosthis.emailDatafetch[d].clientID);

                        var data = JSON.stringify({
                            clientid: clientIds,
                        });

                        var config = {
                            method: "post",
                            url: `${apiurl}/holdingDetails`,
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': gauthclientseassion
                            },
                            data: data,
                        };

                        axios(config)
                            .then(function (response) {
                                console.log("response", response);

                                var holdingdetails = response.data.data;
                                console.log("response", holdingdetails);
                                if (response.data.msg == "No Data Available") {
                                    axiosThis.usernodata = true;
                                    axiosThis.printsumgetloader = false;
                                    axiosThis.usertotaldata = false;
                                } else {
                                    // console.log("holdingdetails", axiosThis.holdingdetails);
                                    for (var i = 0; i < holdingdetails.length; i++) {
                                        axiosThis.sumget.push(holdingdetails[i].Summary);
                                        console.log("sumget--1", axiosThis.sumget);

                                    }
                                }
                            })
                            .catch(function (error) {
                                console.log(error);
                            });
                    }

                    setTimeout(function () {
                        console.log("ppp", axiosThis.sumget)
                        for (var l = 0; l < axiosThis.sumget.length; l++) {
                            var sumdetails = axiosThis.sumget[l];
                            console.log(l, "sumget--1", axiosThis.sumget[l].FullName);

                            if (sumdetails.Quantity > 0) {
                                sumdetails.Avg_Price = Math.abs(sumdetails.Avg_Price.toFixed(2));
                                sumdetails.Net_Value = Math.abs(sumdetails.Net_Value.toFixed(2));

                                axiosThis.printsumget.push(sumdetails);
                                var dataa = JSON.stringify({
                                    userId: "ZP00172",
                                    scripList: "nse_cm|212,nse_cm|" + sumdetails.token,
                                });

                                var tokenconfig = {
                                    method: "post",
                                    url: "https://www.zebull.in/rest/MobullService/api/marketWatch/scripsMW",
                                    headers: {
                                        Authorization: `Bearer ZP00172 ${getTokendata}`,
                                        "Content-Type": "application/json",
                                    },
                                    data: dataa,
                                };

                                axios(tokenconfig)
                                    .then(function (response) {
                                        console.log("MARKETwatch", response.data[1]);
                                        axiosThis.tokengetdata.push(response.data[1]);
                                    })
                                    .catch(function (error) {
                                        console.log(error);
                                    });
                            }
                            if (sumdetails.Quantity == 0) {
                                axiosThis.usernodata = true;
                                axiosThis.printsumgetloader = false;
                            }
                        }
                        setTimeout(function () {

                            for (var o = 0; o < axiosThis.printsumget.length; o++) {
                                console.log("loc", axiosThis.printsumget, "settimeout", axiosThis.tokengetdata)

                                for (var w = 0; w < axiosThis.printsumget.length; w++) {
                                    console.log("|", w);
                                    if (
                                        axiosThis.printsumget[o].Symbol ==
                                        axiosThis.tokengetdata[w].symbolname && axiosThis.printsumget[o].Client_Code == axiosThis.printsumget[w].Client_Code
                                    ) {
                                        console.log(
                                            axiosThis.printsumget[o].Symbol,
                                            "|",
                                            axiosThis.tokengetdata[w].symbolname
                                        );

                                        axiosThis.printsumget[o].sector = Number(
                                            axiosThis.tokengetdata[w].ltp
                                        );
                                        axiosThis.printsumget[o].BuyOrSell = Number(
                                            (
                                                axiosThis.printsumget[o].Quantity *
                                                axiosThis.printsumget[o].sector
                                            ).toFixed(2)
                                        );
                                        axiosThis.printsumget[o].Exchange = Number(
                                            (
                                                axiosThis.printsumget[o].BuyOrSell -
                                                axiosThis.printsumget[o].Net_Value
                                            ).toFixed(2)
                                        );
                                        axiosThis.printsumget[o].MarketCap = Number(
                                            (
                                                (axiosThis.printsumget[o].Exchange /
                                                    axiosThis.printsumget[o].Net_Value) *
                                                100
                                            ).toFixed(2)
                                        );
                                        axiosThis.printsumget[o]["close"] = Number(
                                            axiosThis.tokengetdata[w].close
                                        );
                                        axiosThis.printsumget[o]["pnlval"] = (axiosThis.printsumget[o].sector - axiosThis.tokengetdata[w].close) * axiosThis.printsumget[o].Quantity;
                                        axiosThis.tableDataFinal.push(axiosThis.printsumget[w]);

                                        if (axiosThis.tableDataFinal.length > 0) {
                                            axiosThis.printsumgetloader = false;
                                        }
                                    }
                                }
                            }
                            console.log("printsumget", axiosThis.tableDataFinal, axiosThis.printsumget);

                        }, 1000);

                        //code goes here
                    }, 5000);
                    // console.log("dspemailaccount", axiosthis.dspemailaccount);
                })
                .catch(function (error) {
                    console.log(error);
                });

        },
        subTotal(items, column) {
            console.warn(items, column);
            return items.reduce((sum, item) => sum + item[column], 0).toFixed(2);
        },
    },

    mounted() {
        this.checkClientSync()
        //var userBrokerClientId = localStorage.getItem("client-id");
        // var userBrokerClientId = ["ZVK0106", "ZE1A57", "ZE1A40"]
        // var axiosThis = this;
    },
    computed: {
        totals() {
            const totals = this.tableDataFinal.reduce(
                (acc, d) => {
                    acc.Quantity += d.Quantity;
                    acc.Avg_Price += d.Avg_Price;
                    acc.Net_Value += d.Net_Value;
                    acc.sector += d.sector;
                    acc.close += d.close;
                    acc.BuyOrSell += d.BuyOrSell;
                    acc.Exchange += d.Exchange;
                    acc.pnlval += d.pnlval;
                    return acc;
                },
                {
                    Quantity: 0,
                    Avg_Price: 0.00,
                    Net_Value: 0.00,
                    sector: 0.00,
                    BuyOrSell: 0.00,
                    Exchange: 0.00,
                    pnlval: 0.00,
                    none: "",
                }
            );
            totals.Avg_Price = totals.Avg_Price.toFixed(2);
            totals.Net_Value = totals.Net_Value.toFixed(2);
            totals.sector = totals.sector.toFixed(2);
            totals.BuyOrSell = totals.BuyOrSell.toFixed(2);
            totals.Exchange = totals.Exchange.toFixed(2);
            totals.pnlval = totals.pnlval.toFixed(2);
            return totals;
        },
        cliidhead() {
            return [
                {
                    text: "Company",
                    value: "FullName",
                    align: "left",
                    filter: (f) => {
                        return (f + "")
                            .toUpperCase()
                            .includes(this["FullName"].toUpperCase());
                    },
                },
                // { text: "Company", value: "names", align: "left" },
                { text: "Qty", value: "Quantity", align: "right" },
                { text: "Buy avg.", value: "Avg_Price", align: "right" },
                { text: "LTP", value: "sector", align: "right" },
                { text: "Buy value", value: "Net_Value", align: "right" },
                { text: "Present value", value: "BuyOrSell", align: "right" },
                { text: "P&L", value: "Exchange", align: "right" },
                { text: "P&L change", value: "MarketCap", align: "right" },
            ];
        },
    },
};
</script>

<style>
.cliidsty tr.v-row-group__header {
    background-color: white !important;
    color: black !important;
    font-weight: 700;
}
</style>