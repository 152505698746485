<template>
  <div>
    <!-- <calendar-heatmap 
            :values="[{ date: '2018-1-31', count: 1.0 }, { date: '2018-2-28', count: 2.9 }, { date: '2018-3-31', count: 4.3 }, { date: '2018-4-30', count: 2.1 }, { date: '2018-5-31', count: 7.2 }, { date: '2018-6-30', count: 3 },
            { date: '2018-12-31', count: 1.0 }, { date: '2018-11-28', count: 2.9 }, { date: '2018-10-31', count: 4.3 }, { date: '2018-9-30', count: 2.1 }, { date: '2018-8-31', count: 7.2 }, { date: '2018-7-30', count: 3 }]"
            :start-date="2018-1-1" end-date="2019-1-1"
            :range-color="['#E8EBED', '#c453f1', '#8253f1', '#5398f1', '#1877F2']" :vertical="false"
            tooltip-unit="Hours active" /> -->
    <calendar-heatmap
      style="font-family: 'Inter', sans-serif !important"
      :values="result"
      :start-date="2020 - 1 - 1"
      end-date="2023-1-1"
      :range-color="['#E8EBED', '#cce1fa', '#95bef3', '#5c9ef1', '#1877F2']"
      :vertical="false"
      tooltip-unit="trade"
    />
  </div>
</template>

<script>
import { CalendarHeatmap } from "vue-calendar-heatmap";
import axios from "axios";

import { apiurl } from "../apiUrl";
export default {
  data() {
    return {
      database: [],
      Datetime: [],
      result: [],
      data: [],
      // datareverse: [],
    };
  },
  components: {
    CalendarHeatmap,
  },
  mounted() {
    var userBrokerClientId = localStorage.getItem('client-id')
    var gauthclientseassion = localStorage.clientsessionstore;
    var axiosThis = this;
    var data = JSON.stringify({
      // clientid: "ZE1A57",
      clientid: userBrokerClientId,

    });

    var config = {
      method: "post",
      url: `${apiurl}/holdingDetails`,
      headers: {
          'Content-Type': 'application/json',
          'Authorization': gauthclientseassion
        },
      data: data,
    };
    axios(config).then(function (response) {
      axiosThis.database = response.data.data;
      // console.log('response yk:',response)
      for (var i = 0; i < axiosThis.database.length; i++) {
        axiosThis.data.push(axiosThis.database[i].Data);
      }
      // console.log("loop:",axiosThis.data)
      for (var j = 0; j < axiosThis.data.length; j++) {
        var data = axiosThis.data[j];
        // console.log("value",data)
        for (var k = 0; k < data.length; k++) {
          axiosThis.Datetime.push(
            data[k].Trade_Date.slice(-4) +
              "-" +
              data[k].Trade_Date.slice(3, 5) +
              "-" +
              data[k].Trade_Date.slice(0, 2)
          );
        }
      }
      var counts = {};
      var DATE = axiosThis.Datetime;
      DATE.forEach(function (x) {
        counts[x] = (counts[x] || 0) + 1;
      });
      var dates = counts;
      axiosThis.result = Object.entries(dates).map(([date, count]) => ({
        date,
        count,
      }));
      console.log(axiosThis.result)

    });
  },
};
</script>