<template>
  <v-container class="pa-0 pa-sm-3 pa-md-3">
    <!-- <v-dialog v-model="popupmodel" width="800">
      <v-card class="rounded-lg" style="overflow: hidden;">
        <div>
          <v-row class=" my-auto">
            <v-col class="d-none text-center trmnucolsl" cols="12" sm="6">
              <div class="pt-3 px-6">
                <p class="ctnbtl headline mb-2" style="color: #505359;">
                  Add your trades via Manually</p>
                <p class="body-2 pb-0 mb-sm-4 pb-sm-8 mb-md-6 pb-md-10" style="color: #6B7280;">
                  You can now
                  add your current and old trades to start
                  tracking
                  them.</p>
                <v-btn @click="popupmodel = false, manualdialog = true, fileuploaddata = false, fileuploaddialog = true"
                  x-large color="warning" dark>
                  Manually
                </v-btn>
                <p class="mb-1 mt-0 pt-0 mb-sm-1 mt-sm-8 pt-sm-8 mb-md-1 mt-md-10 pt-md-10"
                  style="color: #FF8D24; letter-spacing: 0.16em;">
                  <img class="mr-3" src="@/assets/Contract Notes.svg" width="20px">CONTRACT NOTES
                </p>
                <p class="body-1 mb-1 pr-0 pr-sm-6 pr-md-6 mb-sm-4 mb-md-4" style="color: #6B7280;">
                  Contract
                  notes
                  contain all the information about your
                  trades on
                  the
                  exchanges.</p>
              </div>
            </v-col>
            <v-col class="text-center trmnucols2" cols="12" sm="12">
              <div class="pt-3 px-6">

                <p class="ctnbtl headline mb-2" style="color: #505359;">
                  Fetch your trades vai Email</p>
                <p class="body-2 pb-0 mb-sm-4 pb-sm-8 mb-md-6 pb-md-10" style="color: #6B7280;">
                  You can now
                  add your current and old trades to start
                  tracking
                  them.</p>
                <v-btn
                  href="https://accounts.google.com/o/oauth2/v2/auth?scope=https://mail.google.com&access_type=offline&redirect_uri=http://localhost:8080/Dashboard&response_type=code&client_id=272883542308-4s1570et2p3gjhj9imf8s9g54p7kku7o.apps.googleusercontent.com"
                  x-large color="success" depressed dark>
                  Email Fetch
                </v-btn>
                <p class="mb-1 mt-0 pt-0 mb-sm-1 mt-sm-8 pt-sm-8 mb-md-1 mt-md-10 pt-md-10"
                  style="color: #FF8D24; letter-spacing: 0.16em;">
                  <img class="mr-3" src="@/assets/Contract Notes.svg" width="20px">EMAIL ID
                </p>
                <p class="body-1 mb-1 pr-0 pr-sm-6 pr-md-6 mb-sm-4 mb-md-4" style="color: #6B7280;">
                  Email id
                  contain all the information about your
                  trades on
                  the
                  exchanges.</p>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-dialog> -->
    <v-dialog v-model="emailfetchdialog" width="800">
      <v-card class="rounded-lg" style="overflow: hidden">
        <div>
          <v-row class="my-auto">
            <v-col class="trmnucolsl d-none d-md-block" cols="12" md="5">
              <div class="pt-2 pl-6">
                <p class="ctnbtl headline mb-2" style="color: #505359">
                  Add your trades
                </p>
                <p class="body-2" style="color: #6b7280">
                  You can now add your current and old trades to start tracking
                  them.
                </p>
              </div>
              <v-card color="transparent" height="100%" class="elevation-0 d-flex align-end justify-end">
                <img src="@/assets/Add your Demat account.svg" width="50%" />
              </v-card>
            </v-col>
            <v-col class="trmnucols2" cols="12" sm="7">
              <div class="pt-2 px-6 pr-1">
                <v-row no-gutters>
                  <v-col cols="10">
                    <p class="mb-0 body-2" style="
                        color: #ff8d24;
                        letter-spacing: 0.16em;
                        text-transform: uppercase;
                      ">
                      Sync your trades
                    </p>
                  </v-col>
                  <v-col cols="2" class="text-right">
                    <v-btn @click="emailfetchdialog = false" class="mx-2" icon light>
                      <v-icon light> mdi-close </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <div class="pr-6">
                  <p class="ctnbtl title" style="line-height: 28px">
                    Start tracking by sync your email here
                  </p>
                  <p class="text-center pt-14">
                    <!-- <v-btn large color="white"
                      href="https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/gmail.readonly&access_type=offline&redirect_uri=https://wysely-a1dd8.firebaseapp.com/Accounts&response_type=code&client_id=272883542308-2g2ofnhbibuqi436ttgg66sljd0vmbcb.apps.googleusercontent.com"
                      x-large>
                      <img class="pa-2 btn rounded-lg" width="40px" src="@/assets/Gmail.svg" /><span><b>Sign in with
                          Gmail</b></span></v-btn> -->

                    <v-btn
                      href="https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/gmail.readonly&access_type=offline&redirect_uri=https://app.wysely.in/Dashboard&response_type=code&client_id=841602117530-fflcske9v6nltk6spfj4gramgbsmhn0l.apps.googleusercontent.com"
                      x-large color="success" depressed dark>
                      Email Fetch
                    </v-btn>
                  </p>
                  <p class="
                      mb-1
                      mt-0
                      pt-0
                      mb-sm-1
                      mt-sm-8
                      pt-sm-8
                      mb-md-1
                      mt-md-10
                      pt-md-10
                    " style="color: #ff8d24; letter-spacing: 0.16em">
                    <img class="mr-3" src="@/assets/Contract Notes.svg" width="20px" />EMAIL ID
                  </p>
                  <p class="body-1 mb-1 pr-0 pr-sm-6 pr-md-6 mb-sm-4 mb-md-4" style="color: #6b7280">
                    Email id contain all the information about your trades on
                    the exchanges.
                  </p>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-dialog>

    <div v-if="awesome" class="pt-16 pa-6 pa-sm-4 pa-md-4">
      <p class="lstctntit headline mb-1">Dashboard</p>
      <p class="body-1" color="#878B93">
        Snapshot of your holdings and trading activity
      </p>

      <v-row>
        <v-col cols="12" sm="8" md="8">
          <div class="pt-4">
            <p class="body-1 mb-1" color="#878B93">Wealth segmentation</p>
            <v-card outlined class="elevation-0 rounded-lg">
              <v-row>
                <v-col cols="12" sm="5" md="5" class="my-auto">
                  <div class="pa-8">
                    <img src="@/assets/Wealth segmentation chart bw.svg" width="100%" />
                  </div>
                </v-col>
                <v-col cols="12" sm="7" md="7" class="my-auto pr-5">
                  <div class="text-center">
                    <img src="@/assets/No trades yet.svg" />
                    <p class="title mb-2">No trades yet</p>
                    <p class="body-1 px-16">
                      You haven’t added any trades to your account. Start adding
                      your trades to track them.
                    </p>

                    <!-- <v-btn
                      @click="
                        (manualdialog = true),
                          (fileuploaddata = false),
                          (fileuploaddialog = true)
                      "
                      large
                      outlined
                      color="#1877F2"
                    >
                      Add your trades!</v-btn
                    > -->
                    <v-btn @click="emailfetchdialog = true" large outlined color="#1877F2">
                      Add your trades!</v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-col>

        <!-- <v-col cols="12" sm="4" md="4">
          <div class="pt-4">
            <p class="body-1 mb-1" color="#878B93">P&L summary</p>
            <v-card outlined class="elevation-0 rounded-lg pa-5">
              <div class="mx-auto">
                <v-row>
                  <v-col>
                    <p class="body-2 mb-0" style="
                        color: #ff8d24;
                        letter-spacing: 0.16em;
                        text-transform: uppercase;
                      ">
                      Total Capital
                    </p>
                    <span class="ctnbtl title">0</span>
                  </v-col>
                  <v-col>
                    <p class="body-2 mb-0" style="
                        color: #ff8d24;
                        letter-spacing: 0.16em;
                        text-transform: uppercase;
                      ">
                      Realised P&L
                    </p>
                    <span class="ctnbtl title">0</span>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <p class="body-2 mb-0" style="
                        color: #ff8d24;
                        letter-spacing: 0.16em;
                        text-transform: uppercase;
                      ">
                      Current Value
                    </p>
                    <span class="ctnbtl title">0</span>
                  </v-col>
                  <v-col>
                    <p class="body-2 mb-0" style="
                        color: #ff8d24;
                        letter-spacing: 0.16em;
                        text-transform: uppercase;
                      ">
                      UNRealised P&L
                    </p>
                    <span class="ctnbtl title">0</span>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </div>
          <div class="pt-6">
            <p class="body-1 mb-1" color="#878B93">Fees & Taxes</p>
            <v-card outlined class="elevation-0 rounded-lg pa-5">
              <div class="mx-auto">
                <v-row>
                  <v-col>
                    <p class="body-2 mb-0" style="
                        color: #ff8d24;
                        letter-spacing: 0.16em;
                        text-transform: uppercase;
                      ">
                      Short term P&L
                    </p>
                    <span class="ctnbtl title">0</span>
                  </v-col>
                  <v-col>
                    <p class="body-2 mb-0" style="
                        color: #ff8d24;
                        letter-spacing: 0.16em;
                        text-transform: uppercase;
                      ">
                      Fees paid
                    </p>
                    <span class="ctnbtl title">0</span>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <p class="body-2 mb-0" style="
                        color: #ff8d24;
                        letter-spacing: 0.16em;
                        text-transform: uppercase;
                      ">
                      Long term P&L
                    </p>
                    <span class="ctnbtl title">0</span>
                  </v-col>
                  <v-col>
                    <p class="body-2 mb-0" style="
                        color: #ff8d24;
                        letter-spacing: 0.16em;
                        text-transform: uppercase;
                      ">
                      Taxes Liable
                    </p>
                    <span class="ctnbtl title">0</span>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </div>
          <div class="pt-6">
            <p class="body-1 mb-1" color="#878B93">Upcoming events</p>
            <v-card outlined class="elevation-0 rounded-lg px-4">
              <div>
                <v-list-item>
                  <div class="text-center pa-2">
                    <p class="pa-16">No upcoming events for your portfolio</p>
                  </div>
                </v-list-item>
                <v-divider></v-divider>
              </div>
            </v-card>
          </div>
        </v-col> -->
        <v-col cols="12" sm="4" md="4">
          <div class="pt-4">
            <p class="body-1 mb-1" color="#878B93">Holding summary</p>
            <v-card outlined class="elevation-0 rounded-lg pa-5">
              <div class="mx-auto">
                <v-row>
                  <v-col>
                    <p class="body-2 mb-0" style="
                        color: #ff8d24;
                        letter-spacing: 0.16em;
                        text-transform: uppercase;
                      ">
                      TOTAL INVESTMENT
                    </p>
                    <span class="ctnbtl sumval" style="line-height: 28px; color: #505359">{{
                        Math.abs(holdingtotal.Net_Value).toFixed(2)
                    }}</span>
                  </v-col>
                  <v-col>
                    <p class="body-2 mb-0" style="
                        color: #ff8d24;
                        letter-spacing: 0.16em;
                        text-transform: uppercase;
                      ">
                      HOLDINGS VALUE
                    </p>
                    <span class="ctnbtl sumval" style="line-height: 28px; color: #505359">{{ holdingtotal.BuyOrSell
                    }}</span>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <p class="body-2 mb-0" style="
                        color: #ff8d24;
                        letter-spacing: 0.16em;
                        text-transform: uppercase;
                      ">
                      TOTAL P&L
                    </p>
                    <span class="ctnbtl sumval" style="line-height: 28px; color: #505359">
                      {{ holdingtotal.Exchange }}
                    </span>
                  </v-col>
                  <v-col>
                    <p class="body-2 mb-0" style="
                        color: #ff8d24;
                        letter-spacing: 0.16em;
                        text-transform: uppercase;
                      ">
                      TODAY'S P&L
                    </p>
                    <span class="ctnbtl sumval" style="line-height: 28px; color: #505359">
                      {{ holdingtotal.pnlval }}
                    </span>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </div>
          <div class="pt-6">
            <p class="body-1 mb-1" color="#878B93">P&L summary</p>
            <v-card outlined class="elevation-0 rounded-lg pa-5">
              <div class="mx-auto">
                <v-row>
                  <v-col>
                    <p class="body-2 mb-0" style="
                        color: #ff8d24;
                        letter-spacing: 0.16em;
                        text-transform: uppercase;
                      ">
                      Total Capital
                    </p>
                    <span class="ctnbtl sumval" style="line-height: 28px; color: #505359">
                      {{ Math.abs(pnltotal.Mtm.toLocaleString()).toFixed(2) }}
                    </span>
                  </v-col>
                  <v-col>
                    <p class="body-2 mb-0" style="
                        color: #ff8d24;
                        letter-spacing: 0.16em;
                        text-transform: uppercase;
                      ">
                      Realised P&L
                    </p>
                    <div v-if="pnltotal.Quantity == 0">
                      <span v-if="pnltotal.Mtm > 0" class="ctnbtl sumval" style="line-height: 28px; color: #34a853">
                        {{ pnltotal.Mtm.toLocaleString() }}
                      </span>
                      <span v-else class="ctnbtl sumval" style="line-height: 28px; color: #e12626">
                        {{ pnltotal.Mtm.toLocaleString() }}
                      </span>
                    </div>
                    <span v-else class="ctnbtl sumval" style="line-height: 28px">0.00</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <p class="body-2 mb-0" style="
                        color: #ff8d24;
                        letter-spacing: 0.16em;
                        text-transform: uppercase;
                      ">
                      Current Value
                    </p>
                    <span class="ctnbtl sumval" style="line-height: 28px; color: #505359">
                      {{ Math.abs(pnltotal.Net_rate.toLocaleString()).toFixed(2) }}
                    </span>
                  </v-col>
                  <v-col>
                    <p class="body-2 mb-0" style="color: #ff8d24;
                        letter-spacing: 0.16em;
                        text-transform: uppercase;
                      ">
                      UNRealised P&L
                    </p>
                    <div v-if="pnltotal.Quantity != 0">
                      <span v-if="pnltotal.Mtm > 0" class="ctnbtl sumval" style="line-height: 28px; color: #34a853">
                        {{ pnltotal.Mtm.toLocaleString() }}
                      </span>
                      <span v-else class="ctnbtl sumval" style="line-height: 28px; color: #e12626">
                        {{ pnltotal.Mtm.toLocaleString() }}
                      </span>
                    </div>
                    <span v-else class="ctnbtl sumval" style="line-height: 28px">0.00</span>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </div>
          <div class="pt-6">
            <p class="body-1 mb-1" color="#878B93">Upcoming events</p>
            <v-card outlined class="elevation-0 rounded-lg px-4">
              <div>
                <v-list-item>
                  <div class="text-center pa-2">
                    <p class="pa-16">No upcoming events for your portfolio</p>
                  </div>
                </v-list-item>
                <v-divider></v-divider>
              </div>
            </v-card>
          </div>
        </v-col>
      </v-row>
      <v-dialog v-model="manualdialog" width="800">
        <v-card class="rounded-lg" style="overflow: hidden">
          <div v-if="fileuploaddialog">
            <v-card class="rounded-lg" style="overflow: hidden">
              <div>
                <v-row class="my-auto">
                  <v-col class="trmnucolsl" cols="12" sm="5" md="5">
                    <div class="pt-4 px-8">
                      <p class="ctnbtl headline mb-2" style="color: #505359">
                        Add your trades
                      </p>
                      <p class="body-2 pb-0 mb-sm-16 pb-sm-16 mb-md-16 pb-md-16" style="color: #6b7280">
                        You can now add your current and old trades to start
                        tracking them.
                      </p>

                      <p class="
                          mb-1
                          mt-0
                          pt-0
                          mb-sm-1
                          mt-sm-16
                          pt-sm-16
                          mb-md-1
                          mt-md-16
                          pt-md-16
                        " style="color: #ff8d24; letter-spacing: 0.16em">
                        <img class="mr-3" src="@/assets/Contract Notes.svg" width="20px" />CONTRACT NOTES
                      </p>
                      <p class="body-1 mb-1 pr-0 pr-sm-6 pr-md-6 mb-sm-4 mb-md-4" style="color: #6b7280">
                        Contract notes contain all the information about your
                        trades on the exchanges.
                      </p>
                    </div>
                  </v-col>

                  <v-col class="trmnucols2" cols="12" sm="7" md="7">
                    <v-form role="form" enctype="multipart/form-data" @submit.prevent="onSubmit">
                      <div class="pt-1 px-8 pt-sm-5 pt-md-5">
                        <p class="mb-2 body-2" style="
                            color: #ff8d24;
                            letter-spacing: 0.16em;
                            text-transform: uppercase;
                          ">
                          Upload Contract Note
                        </p>
                        <p class="
                            ctnbtl
                            title
                            mr-0
                            pr-0 pr-sm-10
                            mr-sm-16
                            pr-md-10
                            mr-md-16
                          " style="line-height: 28px">
                          Start wysely by uploading your daily contract notes
                        </p>

                        <div v-if="!itemsAdded">
                          <div class="form-group">
                            <v-card @ondragover="onChange" :class="dragging ? 'dropAreaDragging' : ''"
                              @dragenter="dragging = true" @dragend="dragging = false" @dragleave="dragging = false"
                              class="
                                fileupload
                                elevation-0
                                rounded-lg
                                text-center
                              " style="border: 1px dashed #d0d3d6">
                              <div class="innerfilup">
                                <img class="mb-6" src="@/assets/file drop.svg" width="25px" />
                                <p class="mb-0">
                                  You can drop your contract notes here to be
                                  uploaded
                                </p>
                              </div>
                              <input type="file" id="items" name="items[]" required multiple @change="onChange" />
                            </v-card>
                          </div>
                        </div>

                        <div v-else>
                          <p><strong>Names</strong></p>
                          <ol>
                            <li v-for="name in itemsNames" :key="name">
                              {{ name }}
                            </li>
                          </ol>
                          <p><strong>Sizes</strong></p>
                          <ol>
                            <li v-for="size in itemsSizes" :key="size">
                              {{ size }}
                            </li>
                          </ol>
                          <p><strong>Total files:</strong> {{ itemsAdded }}</p>
                          <p>
                            <strong>Total upload size:</strong>
                            {{ itemsTotalSize }}
                          </p>
                          <!-- <button @click="removeItems">Remove files</button> -->
                          <v-btn @click="
                            (fileuploaddata = true),
                            (fileuploaddialog = false)
                          ">next</v-btn>
                        </div>

                        <p class="green--text" v-if="successMsg !== ''">
                          {{ successMsg }}
                        </p>
                        <p class="red--text" v-if="errorMsg !== ''">
                          An error has occurred: {{ errorMsg }}
                          <br />retryErrorMessage
                        </p>

                        <p class="green--text" v-if="itemsAdded && itemsAdded < minItems">
                          {{ minFilesErrorMessage }}: {{ minItems }}. <br />
                          retryErrorMessage
                        </p>
                        <p class="red--text" v-if="itemsAdded && itemsAdded > maxItems">
                          {{ maxFilesErrorMessage }}: {{ maxItems }}. <br />
                          retryErrorMessage
                        </p>
                        <p class="text-center pt-6" style="color: #1877f2">
                          Where can you find your contract notes?
                        </p>
                        <!-- <div>
              <button type="submit" class="btn btn-primary btn-black btn-round"
                  :disabled="itemsAdded < minItems || itemsAdded > maxItems">
                  Upload
              </button>
              <button type="button" class="btn btn-default btn-round" @click="removeItems">
                  Cancel
              </button>
          </div> -->
                      </div>
                    </v-form>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </div>

          <div v-else-if="fileuploaddata">
            <div class="pt-8 pb-10 px-8">
              <p class="mb-2 body-2" style="
                  color: #ff8d24;
                  letter-spacing: 0.16em;
                  text-transform: uppercase;
                ">
                Verify Transactions
              </p>
              <p class="
                  ctnbtl
                  title
                  mr-0
                  pr-0
                  mr-sm-16
                  pr-sm-10
                  mr-md-16
                  pr-md-10
                " style="line-height: 28px">
                We’ve found these transactions from your contract notes, please
                verify and approve.
              </p>
              <v-card class="elevation-1">
                <!-- <v-data-table mobile-breakpoint fixed-header disable-sort :headers="headers" :items="desserts"
                  :items-per-page="7" hide-default-footer class="elevation-0">
                  <template v-slot:item="{ item }">
                    <v-simple-checkbox v-model="item.chbox" disabled></v-simple-checkbox>
                  </template>
                </v-data-table> -->
                <v-divider></v-divider>
                <v-row class="px-3 py-3 d-none d-sm-flex d-md-flex">
                  <v-col cols="12" sm="6" md="6" class="pb-0">
                    <!-- @click="button" -->
                    <v-btn small depressed class="ma-0">
                      No trade selected
                    </v-btn>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="pb-0 text-right">
                    <!-- @click="button" -->
                    <v-btn small text color="#1877F2" class="ma-0">
                      Add trades
                    </v-btn>
                    <v-btn @click="
                      (fileuploaddata = false), (fileuploaddialog = false)
                    ">next</v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </div>
          </div>

          <div v-else>
            <div class="pt-8 pb-10 px-8">
              <p class="ctnbtl mb-2 body-2" style="
                  color: #ff8d24;
                  letter-spacing: 0.16em;
                  text-transform: uppercase;
                ">
                Add Transactions
              </p>
              <p class="ctnbtl title mb-16" style="line-height: 28px">
                Based on your selection, we are adding trades to your account.
              </p>

              <v-row class="mb-16">
                <v-col>
                  <div>
                    <p class="caption" style="
                        color: #ff8d24;
                        letter-spacing: 0.16em;
                        text-transform: uppercase;
                      ">
                      Total Trades Added
                    </p>
                    <p>
                      <span class="ctnbtl display-1" style="line-height: 28px">15</span>
                      trades
                    </p>
                  </div>
                </v-col>
                <v-col>
                  <div>
                    <p class="caption" style="
                        color: #ff8d24;
                        letter-spacing: 0.16em;
                        text-transform: uppercase;
                      ">
                      Total Trade Value
                    </p>
                    <p class="ctnbtl display-1" style="line-height: 28px">
                      5,42,152<small>.85</small>
                    </p>
                  </div>
                </v-col>
              </v-row>

              <v-btn large outlined color="#1877F2" @click="awesome = false">Add to wysely</v-btn>
            </div>
          </div>
        </v-card>
      </v-dialog>
    </div>

    <div v-else class="pt-16 pa-6 pa-sm-4 pa-md-4">
      <p class="lstctntit headline mb-1">Dashboard</p>
      <p class="body-1" color="#878B93">
        Snapshot of your holdings and trading activity
      </p>
      <v-row>
        <v-col cols="12" sm="7" md="8">
          <div class="pt-0 pt-sm-4 pt-md-4">
            <p class="body-1 mb-1" color="#878B93">Wealth segmentation</p>
            <v-card outlined class="elevation-0 rounded-lg">
              <v-row>
                <v-col cols="12" sm="12" md="5" class="my-auto">
                  <div class="
                      ma-6
                      mb-0
                      pa-6
                      py-0
                      pb-sm-0
                      pa-sm-5 pa-md-5
                      ma-sm-0 ma-md-0
                    ">
                    <WealthSegmentation />
                    <!-- <img src="@/assets/Wealth segmentation chart.svg" width="100%" > -->
                  </div>
                </v-col>
                <v-col cols="12" sm="12" md="7" class="
                    text-center text-sm-left text-md-left
                    pt-0
                    mt-0
                    my-8
                    pr-5
                    my-sm-0
                    pr-sm-0
                    my-md-8
                    pr-md-5
                  ">
                  <div>
                    <v-row class="my-auto mx-sm-auto">
                      <v-col cols="6" sm="6" md="6" class="pr-0 pr-sm-0">
                        <div class="mx-auto my-0 mr-sm-0 ma-sm-3 ma-md-3">
                          <p class="body-2" style="
                              color: #1877f2;
                              letter-spacing: 0.16em;
                              text-transform: uppercase;
                            ">
                            <v-icon class="pr-2" size="12" color="#1877F2">
                              mdi-checkbox-blank-circle</v-icon>Positions
                          </p>
                          <!-- <span class="ctnbtl title pl-sm-0 pl-md-5"
                            style="color: #505359">3,22,800<small>.00</small></span> -->
                          <span class="ctnbtl title pl-sm-0 pl-md-5" style="color: #505359">0<small>.00</small></span>
                        </div>
                      </v-col>
                      <v-divider class="d-none d-md-flex" vertical></v-divider>
                      <v-col cols="6" sm="6" md="6" class="pr-0 pr-sm-0">
                        <div class="mx-auto my-0 mr-sm-0 ma-sm-3 ma-md-3">
                          <p class="body-2" style="
                              color: #ff8d24;
                              letter-spacing: 0.16em;
                              text-transform: uppercase;
                            ">
                            <v-icon class="pr-2" size="12" color="#FF8D24">
                              mdi-checkbox-blank-circle</v-icon>Holdings
                          </p>
                          <!-- <span class="ctnbtl title pl-sm-0 pl-md-5"
                            style="color: #505359">4,89,800<small>.00</small></span> -->
                          <span class="ctnbtl title pl-sm-0 pl-md-5" style="color: #505359">0<small>.00</small></span>
                        </div>
                      </v-col>
                    </v-row>
                    <v-divider class="d-none d-md-flex"></v-divider>
                    <v-row class="mt-3 mx-sm-auto mt-sm-0 mt-md-0">
                      <v-col cols="6" sm="6" md="6" class="pr-0 pr-sm-0">
                        <div class="mx-auto my-0 mr-sm-0 ma-sm-3 ma-md-3">
                          <p class="body-2" style="
                              color: #2ece14;
                              letter-spacing: 0.16em;
                              text-transform: uppercase;
                            ">
                            <v-icon class="pr-2" size="12" color="#2ECE14">
                              mdi-checkbox-blank-circle</v-icon>Currency
                          </p>
                          <!-- <span class="ctnbtl title pl-sm-0 pl-md-5"
                            style="color: #505359">67,300<small>.00</small></span> -->
                          <span class="ctnbtl title pl-sm-0 pl-md-5" style="color: #505359">0<small>.00</small></span>
                        </div>
                      </v-col>
                      <v-divider class="d-none d-md-flex" vertical></v-divider>
                      <v-col cols="6" sm="6" md="6" class="pr-0 pr-sm-0">
                        <div class="mx-auto my-0 mr-sm-0 ma-sm-3 ma-md-3">
                          <p class="body-2" style="
                              color: #00b2fe;
                              letter-spacing: 0.16em;
                              text-transform: uppercase;
                            ">
                            <v-icon class="pr-2" size="12" color="#00B2FE">
                              mdi-checkbox-blank-circle</v-icon>Commodity
                          </p>
                          <!-- <span class="ctnbtl title pl-sm-0 pl-md-5"
                            style="color: #505359">92,570<small>.00</small></span> -->
                          <span class="ctnbtl title pl-sm-0 pl-md-5" style="color: #505359">0<small>.00</small></span>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </div>

          <!-- <div class="pt-6">
            <p class="body-1 mb-1" color="#878B93">Trade curve</p>
            <v-card outlined class="elevation-0 rounded-lg pa-2 pa-sm-4 pa-md-3">
              <TradeCurve />
              <img src="@/assets/Trade curve chart.svg" width="100%" >
            </v-card>
          </div> -->

          <!-- <div class="pt-6">
            <p class="body-1 mb-1" color="#878B93">Holdings curve</p>
            <v-card outlined class="elevation-0 rounded-lg pa-2 pa-sm-4 pa-md-3">
              <HoldingCurve />
              <img src="@/assets/Holdings curve chart.svg" width="100%" >
            </v-card>
          </div> -->
        </v-col>

        <!-- <v-col cols="12" sm="5" md="4">
          <div class="pt-0 pt-sm-4 pt-md-4">
            <p class="body-1 mb-1" color="#878B93">P&L summary</p>
            <v-card outlined class="elevation-0 rounded-lg pa-6 pr-2">
              <div class="mx-auto">
                <v-row>
                  <v-col class="pa-2">
                    <p class="body-2 mb-0" style="
                        color: #ff8d24;
                        letter-spacing: 0.16em;
                        text-transform: uppercase;
                      ">
                      Total Capital
                    </p>
                    <span class="ctnbtl title">3,22,800<small>.00</small></span>
                  </v-col>
                  <v-col class="pa-2">
                    <p class="body-2 mb-0" style="
                        color: #ff8d24;
                        letter-spacing: 0.16em;
                        text-transform: uppercase;
                      ">
                      Realised P&L
                    </p>
                    <span class="ctnbtl title" style="color: #e12525">2,800<small>.00</small></span>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="pa-2">
                    <p class="body-2 mb-0" style="
                        color: #ff8d24;
                        letter-spacing: 0.16em;
                        text-transform: uppercase;
                      ">
                      Current Value
                    </p>
                    <span class="ctnbtl title">6,54,800<small>.00</small></span>
                  </v-col>
                  <v-col class="pa-2">
                    <p class="body-2 mb-0" style="
                        color: #ff8d24;
                        letter-spacing: 0.16em;
                        text-transform: uppercase;
                      ">
                      UNRealised P&L
                    </p>
                    <span class="ctnbtl title" style="color: #34a853">6,54,800<small>.00</small></span>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </div>
          <div class="pt-6">
            <p class="body-1 mb-1" color="#878B93">Fees & Taxes</p>
            <v-card outlined class="elevation-0 rounded-lg pa-6 pr-2">
              <div class="mx-auto">
                <v-row>
                  <v-col class="pa-2">
                    <p class="body-2 mb-0" style="
                        color: #ff8d24;
                        letter-spacing: 0.16em;
                        text-transform: uppercase;
                      ">
                      Short term P&L
                    </p>
                    <span class="ctnbtl title" style="color: #34a853">3,22,800<small>.00</small></span>
                  </v-col>
                  <v-col class="pa-2">
                    <p class="body-2 mb-0" style="
                        color: #ff8d24;
                        letter-spacing: 0.16em;
                        text-transform: uppercase;
                      ">
                      Fees paid
                    </p>
                    <span class="ctnbtl title">2,800<small>.00</small></span>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="pa-2">
                    <p class="body-2 mb-0" style="
                        color: #ff8d24;
                        letter-spacing: 0.16em;
                        text-transform: uppercase;
                      ">
                      Long term P&L
                    </p>
                    <span class="ctnbtl title" style="color: #34a853">6,54,800<small>.00</small></span>
                  </v-col>
                  <v-col class="pa-2">
                    <p class="body-2 mb-0" style="
                        color: #ff8d24;
                        letter-spacing: 0.16em;
                        text-transform: uppercase;
                      ">
                      Taxes Liable
                    </p>
                    <span class="ctnbtl title">14,800<small>.00</small></span>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </div>
          <div class="pt-6">
            <p class="body-1 mb-1" color="#878B93">Upcoming events</p>
            <v-card outlined class="elevation-0 rounded-lg px-3">
              <div v-for="n in 6" :key="n">
                <v-list-item>
                  <v-row>
                    <v-col cols="2" class="pl-1 text-center">
                      <v-list-item-content width="100">
                        <v-list-item-subtitle style="color: #ff8d24; text-transform: uppercase">
                          <span>Aug</span>
                        </v-list-item-subtitle>
                        <v-list-item-title style="color: #ff8d24">
                          <span class="lstctntit body-1">22</span>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-col>
                    <v-col cols="10">
                      <v-list-item class="pl-0">
                        <v-list-item-content>
                          <v-list-item-title>Laurus Labs</v-list-item-title>
                          <v-list-item-subtitle>Dividend <strong>33/sh</strong>
                          </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-content class="text-right">
                          <v-list-item-title class="lstctntit">
                            881.40
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-list-item>
                <v-divider></v-divider>
              </div>
            </v-card>
          </div>
        </v-col> -->
        <v-col cols="12" sm="4" md="4">
          <div class="pt-4">
            <p class="body-1 mb-1" color="#878B93">Holding summary</p>
            <v-card outlined class="elevation-0 rounded-lg pa-5">
              <div class="mx-auto">
                <v-row>
                  <v-col>
                    <p class="body-2 mb-0" style="
                        color: #ff8d24;
                        letter-spacing: 0.16em;
                        text-transform: uppercase;
                      ">
                      TOTAL INVESTMENT
                    </p>
                    <span class="ctnbtl sumval" style="line-height: 28px; color: #505359">{{
                        Math.abs(holdingtotal.Net_Value).toFixed(2)
                    }}</span>
                  </v-col>
                  <v-col>
                    <p class="body-2 mb-0" style="
                        color: #ff8d24;
                        letter-spacing: 0.16em;
                        text-transform: uppercase;
                      ">
                      HOLDINGS VALUE
                    </p>
                    <span class="ctnbtl sumval" style="line-height: 28px; color: #505359">{{ holdingtotal.BuyOrSell
                    }}</span>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <p class="body-2 mb-0" style="
                        color: #ff8d24;
                        letter-spacing: 0.16em;
                        text-transform: uppercase;
                      ">
                      TOTAL P&L
                    </p>
                    <span class="ctnbtl sumval" style="line-height: 28px; color: #505359">
                      {{ holdingtotal.Exchange }}
                    </span>
                  </v-col>
                  <v-col>
                    <p class="body-2 mb-0" style="
                        color: #ff8d24;
                        letter-spacing: 0.16em;
                        text-transform: uppercase;
                      ">
                      TODAY'S P&L
                    </p>
                    <span class="ctnbtl sumval" style="line-height: 28px; color: #505359">
                      {{ holdingtotal.pnlval }}
                    </span>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </div>
          <div class="pt-6">
            <p class="body-1 mb-1" color="#878B93">P&L summary</p>
            <v-card outlined class="elevation-0 rounded-lg pa-5">
              <div class="mx-auto">
                <v-row>
                  <v-col>
                    <p class="body-2 mb-0" style="
                        color: #ff8d24;
                        letter-spacing: 0.16em;
                        text-transform: uppercase;
                      ">
                      Total Capital
                    </p>
                    <span class="ctnbtl sumval" style="line-height: 28px; color: #505359">
                      {{ Math.abs(pnltotal.Mtm.toLocaleString()).toFixed(2) }}
                    </span>
                  </v-col>
                  <v-col>
                    <p class="body-2 mb-0" style="
                        color: #ff8d24;
                        letter-spacing: 0.16em;
                        text-transform: uppercase;
                      ">
                      Realised P&L
                    </p>
                    <div v-if="pnltotal.Quantity == 0">
                      <span v-if="pnltotal.Mtm > 0" class="ctnbtl sumval" style="line-height: 28px; color: #34a853">
                        {{ pnltotal.Mtm.toLocaleString() }}
                      </span>
                      <span v-else-if="pnltotal.Mtm == 0" class="ctnbtl sumval" style="line-height: 28px">0.00</span>
                      <span v-else class="ctnbtl sumval" style="line-height: 28px; color: #e12626">
                        {{ pnltotal.Mtm.toLocaleString() }}
                      </span>
                    </div>
                    <span v-else class="ctnbtl sumval" style="line-height: 28px">0.00</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <p class="body-2 mb-0" style="
                        color: #ff8d24;
                        letter-spacing: 0.16em;
                        text-transform: uppercase;
                      ">
                      Current Value
                    </p>
                    <span class="ctnbtl sumval" style="line-height: 28px; color: #505359">
                      {{ Math.abs(pnltotal.Net_rate.toLocaleString()).toFixed(2) }}
                    </span>
                  </v-col>
                  <v-col>
                    <p class="body-2 mb-0" style="color: #ff8d24;
                        letter-spacing: 0.16em;
                        text-transform: uppercase;
                      ">
                      UNRealised P&L
                    </p>
                    <div v-if="pnltotal.Quantity != 0">
                      <span v-if="pnltotal.Mtm > 0" class="ctnbtl sumval" style="line-height: 28px; color: #34a853">
                        {{ pnltotal.Mtm.toLocaleString() }}
                      </span>
                      <span v-else class="ctnbtl sumval" style="line-height: 28px; color: #e12626">
                        {{ pnltotal.Mtm.toLocaleString() }}
                      </span>
                    </div>
                    <span v-else class="ctnbtl sumval" style="line-height: 28px">0.00</span>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </div>
          <div class="pt-6">
            <p class="body-1 mb-1" color="#878B93">Upcoming events</p>
            <v-card outlined class="elevation-0 rounded-lg px-4">
              <div>
                <v-list-item>
                  <div class="text-center pa-2">
                    <p class="pa-16">No upcoming events for your portfolio</p>
                  </div>
                </v-list-item>
                <v-divider></v-divider>
              </div>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </div>
    <div>
      <v-snackbar style="z-index: 2 !important;" transition="slide-x-reverse-transition" v-model="snackbar"
        :timeout="20000" :value="true" color="warning" absolute outlined top right>
        <v-icon color="warning">mdi-alert-outline</v-icon>
        Session <strong>expired</strong> Kindly Login again.
      </v-snackbar>
    </div>
  </v-container>

</template>
<script>
import axios from "axios";
import { apiurl } from "../apiUrl";
import WealthSegmentation from "../charts/WealthSegmentation.vue";
import HoldingCurve from "../charts/HoldingCurve.vue";
import TradeCurve from "../charts/TradeCurve.vue";
// import qs from 'qs'
export default {
  props: {
    postURL: {
      type: String,
      required: true,
    },
    minItems: {
      type: Number,
      default: 1,
    },
    maxItems: {
      type: Number,
      default: 5,
    },
    method: {
      type: String,
      default: "post",
    },
    postMeta: {
      type: [String, Array, Object],
      default: "",
    },
    postData: {
      type: [Object],
      default: () => { },
    },
    postHeader: {
      type: [Object],
      default: () => { },
    },
    successMessagePath: {
      type: String,
      required: true,
    },
    errorMessagePath: {
      type: String,
      required: true,
    },

    showHttpMessages: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      snackbar: false,
      /* eslint-disable */
      emailfetchdialog: false,

      manualdialog: false,
      fileuploaddata: true,
      fileuploaddialog: false,
      // popupmodel: true,
      awesome: false,
      dialog: false,
      headers: [
        { text: "Instruments", value: "" },
        { text: "Qty", value: "" },
        { text: "Buy avg.", value: "" },
        { text: "Invest value", value: "" },
        { text: "Select trade", value: "" },
      ],
      response: "",
      res: "",
      res_ref: "",

      dragging: false,
      items: [],
      itemsAdded: "",
      itemsNames: [],
      itemsSizes: [],
      itemsTotalSize: "",
      formData: "",
      successMsg: "",
      errorMsg: "",
      isLoaderVisible: false,

      holdingdetails: [],
      holdingsumget: [],
      holdingclsval: [],
      holdingclosepnl: 0,
      holdingprint: [],
      holdingtokens: [],
      holdingtableData: [],

      pnldetails: [],
      pnlsumget: [],
      pnlprint: [],
      pnltoken: [],
      pnltableData: [],
    };
  },

  methods: {
    refreshTokenCheck(email) {
      var axiosthis = this;
      console.warn("functiooooonnn");
      var checkrefreshtoken = localStorage.clientsessionstore;
      // console.log("mmmmmmmmmmmm", checkrefreshtoken, localStorage.getItem('localstroageemailfetch'));
      var data = JSON.stringify({
        Email: email,
      });
      console.log("refresh token user email", data);
      var config = {
        method: "post",
        url: `${apiurl}/checkrefreshtoken`,
        headers: {
          "Content-Type": "application/json",
          Authorization: checkrefreshtoken,
        },
        data: data,
      };
      console.log("checkrefreshtoken", checkrefreshtoken);
      axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data));
          console.log("checkrefreshtoken response", response);
          console.log("checkrefreshtoken msg ", response.data.msg);

          if (response.data.msg == "Refresh Token Available") {
            axiosthis.popupmodel = false;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    bytesToSize(bytes) {
      const sizes = ["Bytes", "KB", "MB"];
      if (bytes === 0) return "n/a";
      let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      if (i === 0) return bytes + " " + sizes[i];
      return (bytes / Math.pow(1024, i)).toFixed(2) + " " + sizes[i];
    },

    onChange(e) {
      this.successMsg = "";
      this.errorMsg = "";
      this.formData = new FormData();
      let files = e.target.files || e.dataTransfer.files;
      this.itemsAdded = files.length;
      let fileSizes = 0;
      for (let x in files) {
        if (!isNaN(x)) {
          this.items = e.target.files[x] || e.dataTransfer.files[x];
          this.itemsNames[x] = files[x].name;
          this.itemsSizes[x] = this.bytesToSize(files[x].size);
          fileSizes += files[x].size;
          this.formData.append("items[]", this.items);
        }
      }
      this.itemsTotalSize = this.bytesToSize(fileSizes);
    },

    removeItems() {
      this.items = "";
      this.itemsAdded = "";
      this.itemsNames = [];
      this.itemsSizes = [];
      this.itemsTotalSize = "";
      this.dragging = false;
    },

    onSubmit() {
      this.isLoaderVisible = true;

      if (
        (typeof this.postMeta === "string" && this.postMeta !== "") ||
        (typeof this.postMeta === "object" &&
          Object.keys(this.postMeta).length > 0)
      ) {
        this.formData.append("postMeta", this.postMeta);
      }

      if (
        typeof this.postData === "object" &&
        this.postData !== null &&
        Object.keys(this.postData).length > 0
      ) {
        for (var key in this.postData) {
          this.formData.append(key, this.postData[key]);
        }
      }

      if (this.method === "put" || this.method === "post") {
        axios({
          method: this.method,
          url: this.postURL,
          data: this.formData,
          headers: this.postHeader,
        })
          .then((response) => {
            this.isLoaderVisible = false;
            // Show success message
            if (this.showHttpMessages)
              this.successMsg = response + "." + this.successMessagePath;
            this.removeItems();
          })
          .catch((error) => {
            this.isLoaderVisible = false;
            if (this.showHttpMessages)
              this.errorMsg = error + "." + this.errorMessagePath;
            this.removeItems();
          });
      } else {
        if (this.showHttpMessages) this.errorMsg = this.httpMethodErrorMessage;
        this.removeItems();
      }
    },
  },
  mounted() {
    var userBrokerClientId = localStorage.getItem("client-id");
    var gauthclientseassion = localStorage.clientsessionstore
    var getTokendata = localStorage.getItem("getTokendata");

    var axiosThis = this;
    var data = JSON.stringify({
      // clientid: "ZE1A57",
      clientid: userBrokerClientId,

    });

    // holding final api start<-----------------------------------------------
    var holdingconfig = {
      method: "post",
      url: `${apiurl}/holdingDetails`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': gauthclientseassion
      },
      data: data,
    };

    axios(holdingconfig)
      .then(function (response) {
        if (response.data.msg == "Token is Expired." || response.data.msg == "Token is Invalid.") {
          axiosThis.snackbar = true;
          setTimeout(function () {
            axiosThis.$router.push("/signin");

          }, 2000);
        }
        else {
          axiosThis.holdingdetails = response.data.data;
          // console.log("response", response.data.data);
          // console.log("holdingdetails", axiosThis.holdingdetails);
          for (var i = 0; i < axiosThis.holdingdetails.length; i++) {
            axiosThis.holdingsumget.push(axiosThis.holdingdetails[i].Summary);
            console.log("holdingsumget", axiosThis.holdingsumget);
          }

          for (var l = 0; l < axiosThis.holdingsumget.length; l++) {
            var sumdetails = axiosThis.holdingsumget[l];
            if (sumdetails.Quantity > 0) {

              sumdetails.Avg_Price = Math.abs(sumdetails.Avg_Price.toFixed(2));
              sumdetails.Net_Value = Math.abs(sumdetails.Net_Value.toFixed(2));

              axiosThis.holdingprint.push(sumdetails);

              var holddata = JSON.stringify({
                userId: "ZP00172",
                scripList: "nse_cm|212,nse_cm|" + sumdetails.token,
              });

              var tokenconfig = {
                method: "post",
                url: "https://www.zebull.in/rest/MobullService/api/marketWatch/scripsMW",
                headers: {
                  Authorization: `Bearer ZP00172 ${getTokendata}`,
                  "Content-Type": "application/json",
                },
                data: holddata,
              };

              axios(tokenconfig)
                .then(function (response) {
                  axiosThis.holdingtokens.push(response.data[1]);
                })
                .catch(function (error) {
                  console.log(error);
                });
            }
          }

          setTimeout(function () {
            for (var o = 0; o < axiosThis.holdingprint.length; o++) {
              for (var w = 0; w < axiosThis.holdingprint.length; w++) {
                if (
                  axiosThis.holdingprint[o].Symbol ==
                  axiosThis.holdingtokens[w].symbolname
                ) {
                  console.log(
                    axiosThis.holdingprint[o].Symbol,
                    "|",
                    axiosThis.holdingtokens[w].ltp
                  );

                  axiosThis.holdingprint[o].Brok = Number(
                    axiosThis.holdingtokens[w].ltp
                  );
                  axiosThis.holdingprint[o].BuyOrSell = Number(
                    (
                      axiosThis.holdingprint[o].Quantity *
                      axiosThis.holdingprint[o].Brok
                    ).toFixed(2)
                  );
                  axiosThis.holdingprint[o].Exchange = Number(
                    (
                      axiosThis.holdingprint[o].BuyOrSell -
                      axiosThis.holdingprint[o].Net_Value
                    ).toFixed(2)
                  );
                  axiosThis.holdingprint[o].MarketCap = Number(
                    (
                      (axiosThis.holdingprint[o].Exchange /
                        axiosThis.holdingprint[o].Net_Value) *
                      100
                    ).toFixed(2)
                  );
                  axiosThis.holdingprint[o]["close"] = Number(
                    axiosThis.holdingtokens[w].close
                  );
                  axiosThis.holdingprint[o]["pnlval"] = (axiosThis.holdingprint[o].Brok - axiosThis.holdingtokens[w].close) * axiosThis.holdingprint[o].Quantity;

                  axiosThis.holdingtableData.push(axiosThis.holdingprint[o]);

                  axiosThis.holdingclsval.push(axiosThis.holdingtableData[o].close);
                }
              }
            }
            for (var c = 0; c < axiosThis.holdingclsval.length; c++) {
              axiosThis.holdingclosepnl += axiosThis.holdingclsval[c];
            }
          }, 2000);
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    // holding final api end-------------------------------------------------->

    // profit and lose api start
    var pnlconfig = {
      method: "post",
      url: `${apiurl}/datesummary`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': gauthclientseassion
      },
      data: data,
    };

    axios(pnlconfig)
      .then(function (response) {
        // console.log(response.data);
        axiosThis.pnldetails = response.data.data;
        // console.log("pnldetails", axiosThis.pnldetails);
        for (var i = 0; i < axiosThis.pnldetails.length; i++) {
          axiosThis.pnlsumget.push(axiosThis.pnldetails[i].summary);
        }

        for (var r = 0; r < axiosThis.pnlsumget.length; r++) {
          var sumdtl_s1 = axiosThis.pnlsumget[r];

          for (var y = 0; y < sumdtl_s1.length; y++) {
            var sumdtl_s2 = sumdtl_s1[y];
            console.log(sumdtl_s2.token, "sumdtl_s2");
            axiosThis.pnlprint.push(sumdtl_s1);
            console.log(axiosThis.pnlprint, "pnlprint");

            // ////////////////////////
            var pnldata = JSON.stringify({
              userId: "ZP00172",
              scripList: "nse_cm|212,nse_cm|" + sumdtl_s2.token,
            });

            var sumtokenconfig = {
              method: "post",
              url: "https://www.zebull.in/rest/MobullService/api/marketWatch/scripsMW",
              headers: {
                Authorization: `Bearer ZP00172 ${getTokendata}`,
                "Content-Type": "application/json",
              },
              data: pnldata,
            };

            axios(sumtokenconfig)
              .then(function (response) {
                // console.log("dataget2", response.data[1]);
                axiosThis.pnltoken.push(response.data[1]);
                console.log("pnltoken", axiosThis.pnltoken);
              })
              .catch(function (error) {
                console.log(error);
              });
          }
        }

        setTimeout(function () {
          for (var p = 0; p < axiosThis.pnlprint.length; p++) {
            for (var u = 0; u < axiosThis.pnlprint.length; u++) {
              if (
                axiosThis.pnlprint[p][0].token ==
                axiosThis.pnltoken[u].token
              ) {
                axiosThis.pnlprint[p][0]["Ltp"] = Number(
                  axiosThis.pnltoken[u].ltp
                );
                axiosThis.pnlprint[p][0]["Net_rate"] =
                  axiosThis.pnlprint[p][0].Net_Value /
                  axiosThis.pnlprint[p][0].Quantity;
                axiosThis.pnlprint[p][0]["Mtm"] =
                  (axiosThis.pnlprint[p][0].Ltp -
                    axiosThis.pnlprint[p][0].Net_rate) *
                  axiosThis.pnlprint[p][0].Quantity;
                // }
              }
            }
            var summdatas1 = axiosThis.pnlprint[p][0];
            console.log(summdatas1, "summdatas1");

            axiosThis.pnltableData.push(summdatas1);

          }
        }, 2000);

        console.log(axiosThis.pnltableData, "pnltableData");
      })
      .catch(function (error) {
        console.log(error);
      });
    // profit and lose api end-------------------------------------------------->

  },

  components: { WealthSegmentation, HoldingCurve, TradeCurve },

  computed: {
    holdingtotal() {
      const holdingtotal = this.holdingtableData.reduce(
        (acc, d) => {
          acc.Quantity += d.Quantity;
          acc.Avg_Price += d.Avg_Price;
          acc.Net_Value += d.Net_Value;
          acc.Brok += d.Brok;
          acc.close += d.close;
          acc.pnlval += d.pnlval;
          acc.BuyOrSell += d.BuyOrSell;
          acc.Exchange += d.Exchange;
          return acc;
        },
        {
          Quantity: 0,
          Avg_Price: 0.00,
          Net_Value: 0.00,
          Brok: 0.00,
          BuyOrSell: 0.00,
          Exchange: 0.00,
          pnlval: 0.00,
          none: "",
        }
      );
      holdingtotal.Avg_Price = holdingtotal.Avg_Price.toFixed(2);
      holdingtotal.Net_Value = holdingtotal.Net_Value.toFixed(2);
      holdingtotal.Brok = holdingtotal.Brok.toFixed(2);
      holdingtotal.BuyOrSell = holdingtotal.BuyOrSell.toFixed(2);
      holdingtotal.Exchange = holdingtotal.Exchange.toFixed(2);
      holdingtotal.pnlval = holdingtotal.pnlval.toFixed(2);
      return holdingtotal;
    },
    pnltotal() {
      const pnltotal = this.pnltableData.reduce(
        (acc, d) => {
          acc.Net_rate += d.Net_rate;
          acc.Quantity += d.Quantity;
          acc.Mtm += d.Mtm;
          return acc;
        },
        {
          Net_rate: 0,
          Quantity: 0,
          Mtm: 0,
          none: "",
        }
      );
      pnltotal.Net_rate = pnltotal.Net_rate.toFixed(2)
      pnltotal.Mtm = pnltotal.Mtm.toFixed(2)
      return pnltotal;
    },
  },
};
</script>

<style>
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: rgba(128, 128, 128, 0.6);
  border-radius: 16px;
}

.sumval {
  font-size: 22px;
}

.v-application--is-ltr .v-data-table--fixed-header .v-data-footer {
  margin-right: 0;
}

@media only screen and (max-width: 1264px) {
  .sumval {
    font-size: 16px;
  }
}

@media only screen and (max-width: 960px) {
  .sumval {
    font-size: 17px;
  }
}

@media only screen and (max-width: 600px) {
  .sumval {
    font-size: 18px;
  }
}

@media only screen and (max-width: 425px) {
  .sumval {
    font-size: 13px;
  }
}

.fileupload {
  position: relative;
  text-align: center;
}

.fileupload input {
  cursor: pointer !important;
  width: 100% !important;
  height: 180px !important;
  opacity: 0 !important;
}

.innerfilup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>