<template>
  <v-app>
    <v-container class="pa-0 pa-sm-3 pa-md-3">
      <div class="pt-16 pa-6 pa-sm-4 pa-md-4">
        <div>
          <p class="lstctntit headline mb-1">User Profile</p>
          <p class="body-1 mb-0" color="#878B93">
            Update your photo and personal details here.
          </p>
        </div>

        <div class="mt-8">
          <v-row no-gutters class="d-flex flex-column-reverse flex-md-row">
            <!-- <v-col cols="12" md="7">
              <p>Full Name</p>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    color="gray"
                    outlined
                    class="rounded-lg"
                    placeholder="Enter First Name"
                    label="First Name"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    color="gray"
                    outlined
                    class="rounded-lg"
                    placeholder="Last First Name"
                    label="Last Name"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <p>Email Address</p>
              <v-col cols="12" class="pa-0">
                <v-text-field
                  color="gray"
                  outlined
                  class="rounded-lg"
                  placeholder="Enter Email Address"
                  label="Email Address"
                >
                </v-text-field>
              </v-col>

              <v-row>
                <v-col cols="6">
                  <p>Date of Birth</p>
                  <v-menu
                    ref="dobmenu"
                    v-model="dobmenu"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        class="rounded-lg"
                        placeholder="Select Date of Birth"
                        v-model="date"
                        label="DD/MM/YYYY"
                        append-icon="mdi-calendar"
                        outlined
                        color="gray"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="dobmenu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dobmenu.save(date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6">
                  <p>Phone</p>
                  <v-text-field
                    color="gray"
                    outlined
                    class="rounded-lg"
                    placeholder="Enter 10 Digit Number"
                    label="Phone Number"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <p>Family Name</p>
              <v-col cols="12" class="pa-0">
                <v-text-field
                  color="gray"
                  outlined
                  class="rounded-lg"
                  placeholder="Enter Family Name"
                  label="Family Name"
                >
                </v-text-field>
              </v-col>

              <p>Family Members</p>
              <v-col cols="12" class="pa-0">
                <v-select
                  append-icon="mdi-chevron-down"
                  class="rounded-lg"
                  color="gray"
                  placeholder="Select Members"
                  label="Family Members"
                  single-line
                  outlined
                ></v-select>
              </v-col>
              <v-row class="mt-4" style="padding-left: 4px">
                <v-col cols="12" class="pa-0">
                  <v-btn class="ma-2 rounded-lg" large outlined color="#1877F2">
                    Save User Profile
                  </v-btn>

                  <v-btn class="rounded-lg" large text color="#1877F2">
                    Cancel
                  </v-btn>
                </v-col>
              </v-row>
            </v-col> -->
            <v-col cols="12" md="7">
              <p class="mb-2">Name</p>
              <v-text-field
                :readonly="userdataview"
                v-model="UserDetails.username"
                label="Your display name"
                single-line
                outlined
              >
              </v-text-field>

              <p class="mb-2">Email ID</p>
              <v-text-field
                :readonly="userdataview"
                v-model="UserDetails.useremail"
                label="Your email"
                single-line
                outlined
              >
              </v-text-field>

              <v-row>
                <v-col cols="6">
                  <p class="mb-2">Date of birth</p>
                  <v-menu
                    ref="dobmenu"
                    v-model="dobmenu"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :readonly="userdataview"
                        placeholder="Select Date of Birth"
                        v-model="UserDetails.dob"
                        label="YYYY/MM/DD"
                        append-icon="mdi-calendar"
                        outlined
                        color="gray"
                        v-bind="attrs"
                        v-on="on"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="UserDetails.dob"
                      :readonly="userdataview"
                      no-title
                      scrollable
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6">
                  <p class="mb-2">Mobile Number</p>
                  <v-text-field
                    :readonly="userdataview"
                    v-model="UserDetails.mobileno"
                    type="number"
                    label="Your Mobile Number"
                    single-line
                    outlined
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <p class="mb-2">family members count</p>
              <v-text-field
                :readonly="userdataview"
                v-model="UserDetails.nofamilymembers"
                label="Family members"
                single-line
                outlined
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" md="5" class="text-center mb-6">
              <p class="font-weight-bold">Profile Photo</p>
              <!-- <v-avatar color="#E3E7EC" size="160">
                <v-icon color="#FFFFFF" size="140">mdi-account</v-icon>
                <v-sapn v-if='imageUrl'></v-sapn>
                <img  :src="imageUrl" alt="userprofile" size="140"/>
              </v-avatar>
              <div class="mr-n8">
                <v-btn
                @click="onFileChange"
                type="file"
                v-model="image"
                  accept="image/*"
                  class="mx-2 elevation-0 mt-n16 mr-n16"
                  fab
                  dark
                  small
                  multiple
                  color="#FF8D24"
                >
                  <v-icon dark> mdi-camera </v-icon>
                </v-btn>
              </div> -->
              <!-- <div
    class="-imageinput"
    :style="{ 'background-image': `url(${imageData})` }"
    @click="chooseImage"
  > -->
  <v-avatar color="#E3E7EC"  size="160"   src="" :style="{ 'background-image': `url(${imageData})` }"  @click="chooseImage"></v-avatar>
  <div class="mr-n8">
                <v-btn
                @click="chooseImage"
                type="file"
                v-model="image"
                  accept="image/*"
                  class="mx-2 elevation-0 mt-n16 mr-n16"
                  fab
                  dark
                  small
                  multiple
                  color="#FF8D24"
                >
                  <v-icon dark> mdi-camera </v-icon>
                </v-btn>
              </div>  
  <!-- <span
      v-if="!imageData"
      class="placeholder"
    >
      Choose an Image
    </span> -->
    <input
      class="file-input"
      ref="fileInput"
      accept="image/#"
      type="file"
      @input="onSelectFile"
    >
  <!-- </div> -->
              <span class="subtitle-2 gray--text font-weight-regular"
                >Click to upload or drag and drop<br />SVG, PNG, JPG or
                GIF</span
              >
              <v-row class="d-flex justify-center">
                <v-switch loading="none" value input-value="true"></v-switch>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-container>
  </v-app>
</template>

<script>
import { apiurl } from "../../apiUrl";
import axios from "axios";

export default {
  data() {
    return {
      signinemailname: "",
      image: undefined,
      imageData: null,
      imageUrl: '',
      date: null,
      // url: null,
      name: "",
      email: "",
      dobmenu: "",
      mobile: "",
      family: "",
      familys: [
        "Below 2 members",
        "2 to 4 members",
        "5 to 7 members",
        "7 to 9 members",
        "9 or more members",
      ],
      userdataview: true,
      getUserDetails: [],
      UserDetails: [],
      switch1: false,
    };
  },
  mounted() {
    // var axios = require("axios");
    var axiosThis = this;
    var decryptedsrtore = localStorage.decryptedstoredname;
    this.signusername = decryptedsrtore;
    console.log("declocalstore mounted", this.signusername);

    var declocalsrore = localStorage.decryptedstoredData;
    this.signinemailname = declocalsrore;
    console.log("declocalstore mounted", this.signinemailname);

    var data = JSON.stringify({
      email: this.signinemailname,
    });

    var config = {
      method: "post",
      url: `${apiurl}/getUserDetails`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        for (var l = 0; l < response.data.length; l++) {
          axiosThis.getUserDetails.push(response.data[l]);
        }
        axiosThis.UserDetails = axiosThis.getUserDetails[0];
        console.log(axiosThis.UserDetails, "UserDetails");

        // console.log(axiosThis.getUserDetails);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  methods :{
    // onFileChange(e) {
    //   // const file = e.target.files[0];
    //   this.url = URL.createObjectURL(e.target);
    // }
    resizeImg(img, height, width) {
    img.height = height;
    img.width = width;
},
    chooseImage () {
      this.$refs.fileInput.click()
    },

    onSelectFile () {
      const input = this.$refs.fileInput
      const files = input.files
      if (files && files[0]) {
        const reader = new FileReader
        reader.onload = e => {
          this.imageData = e.target.result
        }
        reader.readAsDataURL(files[0])
        this.$emit('input', files[0])
      }
    }
  },
  computed :{
    template: '#image-input',
  }
};
</script>
<style lang="scss">
// .image-input{
  // display: block;
  // width: 50px;
  // height: 50px;
  // cursor: pointer;
  // background-size :cover
  // background-position center !important;
// }
// .placeholder{
//   background: #F0F0F0;
//   width: 100%;
//   height: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   color: #333;
//   font-size: 18px;
// }
.file-input{
  display: none
  }
.profile-container {
    width: 150px;
    height: 150px;
    overflow: hidden;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
}
</style>