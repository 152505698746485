<template>
    <div>
        <!-- <v-data-table :loading="printsumgetloader" mobile-breakpoint fixed-header disable-sort :headers="cliidhead"
            :items="tableDataFinal" item-key="FullName" hide-default-footer style="border: 1px solid #efeef3"
            class="cliidsty elevation-0 rounded-lg" :page.sync="page" :items-per-page="10"
            @page-count="pageCount = $event" group-by="FullName">
            <template v-slot:[`footer`]>
                <v-divider></v-divider>
                <div class=" text-center py-2">
                    <v-pagination v-model="page" :length="pageCount"></v-pagination>
                </div>
            </template>
            <template v-slot:[`group.header`]="{ group, items }">
                <th>{{ group }}</th>
                <td class="text-right"> {{ subTotal(items, 'Quantity') }}
                </td>
                <td class="text-right">{{ subTotal(items, 'Avg_Price') }}</td>
                <td class="text-right">{{ subTotal(items, 'sector') }}</td>
                <td class="text-right">{{ subTotal(items, 'Net_Value') }}</td>
                <td class="text-right">{{ subTotal(items, 'BuyOrSell') }}</td>
                <td class="text-right">{{ subTotal(items, 'Exchange') }}</td>
                <td class="text-right">{{ subTotal(items, 'MarketCap') }}</td>
            </template>
            <template v-slot:[`item.names`]="{ item }">
                <div v-if="item.Client_Code.slice(0, 1) == 'Z'">
                    <img style="border: 1px solid #efeef3" width="24px" class="pa-1 rounded" src="@/assets/Ma_zebu.svg">
                </div>
                <div v-else>
                    {{ item.Client_Code }}
                </div>
            </template>
            <template v-if="usertotaldata" v-slot:[`body.append`]>
                <tr class="black--text">
                    <td class="text-left">
                        <span class="subtitle-1 font-weight-medium pa-1 rounded"
                            style="background-color: #e8ebed; color: #505359">{{ tableDataFinal.length }}
                            holdings</span>
                    </td>
                    <td>{{ totals.none }}</td>
                    <td>{{ totals.none }}</td>
                    <td>{{ totals.none }}</td>
                    <td class="subtitle-1 text-right font-weight-medium">
                        {{ Math.abs(totals.Net_Value).toFixed(2) }}
                    </td>
                    <td class="subtitle-1 text-right font-weight-medium">
                        {{ totals.BuyOrSell }}
                    </td>
                    <td class="subtitle-1 text-right font-weight-medium">
                        <span v-if="totals.Exchange == 0">0.00</span>
                        <span v-else>
                            <span class="font-weight-medium pa-1 rounded" v-if="totals.Exchange > 0"
                                style="background-color: #eeffed; color: #34a853">{{ totals.Exchange }}</span>
                            <span class="font-weight-medium pa-1 rounded" v-else
                                style="background-color: #ffeeee; color: #e12626">{{ totals.Exchange }}</span>
                        </span>
                    </td>
                    <td class="subtitle-1 text-right font-weight-medium">
                        <span v-if="totals.Exchange == 0">0.00%</span>
                        <span v-else>
                            <span class="font-weight-medium pa-1 rounded" v-if="totals.Exchange > 0"
                                style="background-color: #eeffed; color: #34a853">+{{
                                        (
                                            (totals.Exchange / totals.Net_Value) *
                                            100
                                        ).toFixed(2)
                                }}%</span>
                            <span class="font-weight-medium pa-1 rounded" v-else
                                style="background-color: #ffeeee; color: #e12626">{{
                                        (
                                            (totals.Exchange / totals.Net_Value) *
                                            100
                                        ).toFixed(2)
                                }}%</span>
                        </span>
                    </td>
                </tr>
            </template>
            <template v-if="usernodata" v-slot:no-data>
                <v-col class="mx-auto pa-15">
                    <v-img class="align-self-stretch mx-auto" width="100px"
                        :src="require('@/assets/no data folder.svg')" />
                    <h4>No data available</h4>
                </v-col>
            </template>
        </v-data-table> -->

        <v-data-table mobile-breakpoint fixed-header disable-sort :headers="cliidhead" :items="tableDataFinal"
            item-key="FullName" hide-default-footer style="border: 1px solid #efeef3"
            class="cliidsty elevation-0 rounded-lg" :page.sync="page" :items-per-page="10"
            @page-count="pageCount = $event" group-by="FullName" :loading="printsumgetloader" :expanded.sync="expanded">
            <template v-slot:[`footer`]>
                <v-divider></v-divider>
                <div class=" text-center py-2">
                    <v-pagination v-model="page" :length="pageCount"></v-pagination>
                </div>
            </template>
            <template v-slot:[`group.header`]="{ group, toggle, isOpen, items }">
                <!-- <th>{{ group }}</th> -->
                <td colspan="1">
                    {{ group.match(/\((.*?)\)/)[1] }}
                    <v-btn @click="toggle" small icon :ref="group" :data-open="isOpen">
                        <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
                        <v-icon v-else>mdi-chevron-down</v-icon>
                    </v-btn>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td class="text-right">{{ subTotal(items, 'Net_Value') }}</td>
                <td class="text-right">{{ subTotal(items, 'BuyOrSell') }}</td>
                <td class="text-right">{{ subTotal(items, 'Exchange') }}</td>
                <td class="text-right">{{ subTotal(items, 'MarketCap') }}</td>
            </template>
            <template v-slot:[`item.names`]="{ item }">
                <div v-if="item.Client_Code.slice(0, 1) == 'Z'">
                    <img style="border: 1px solid #efeef3" width="24px" class="pa-1 rounded" src="@/assets/Ma_zebu.svg">
                </div>
                <div v-else>
                    <v-avatar tile size="32"><v-card class="elevation-0 pa-1" rounded outlined>
                            <span class="font-weight-bold">
                                {{ item.Client_Code.slice(0, 2) }}

                            </span>
                        </v-card></v-avatar>
                </div>
            </template>
            <template v-if="usertotaldata" v-slot:[`body.append`]>
                <tr class="black--text">
                    <td class="text-left">
                        <span class="subtitle-1 font-weight-medium pa-1 rounded mr-1"
                            style="background-color: #e8ebed; color: #505359">{{ clientids.length }}
                            accounts</span>

                        <span class="subtitle-1 font-weight-medium pa-1 rounded"
                            style="background-color: #e8ebed; color: #505359">{{ tableDataFinal.length }}
                            holdings</span>
                    </td>
                    <td>{{ totals.none }}</td>
                    <td>{{ totals.none }}</td>
                    <td>{{ totals.none }}</td>
                    <td class="subtitle-1 text-right font-weight-medium">
                        {{ Math.abs(totals.Net_Value).toFixed(2) }}
                    </td>
                    <td class="subtitle-1 text-right font-weight-medium">
                        {{ totals.BuyOrSell }}
                    </td>
                    <td class="subtitle-1 text-right font-weight-medium">
                        <span v-if="totals.Exchange == 0">0.00</span>
                        <span v-else>
                            <span class="font-weight-medium pa-1 rounded" v-if="totals.Exchange > 0"
                                style="background-color: #eeffed; color: #34a853">{{ totals.Exchange }}</span>
                            <span class="font-weight-medium pa-1 rounded" v-else
                                style="background-color: #ffeeee; color: #e12626">{{ totals.Exchange }}</span>
                        </span>
                    </td>
                    <td class="subtitle-1 text-right font-weight-medium">
                        <span v-if="totals.Exchange == 0">0.00%</span>
                        <span v-else>
                            <span class="font-weight-medium pa-1 rounded" v-if="totals.Exchange > 0"
                                style="background-color: #eeffed; color: #34a853">+{{
                                        (
                                            (totals.Exchange / totals.Net_Value) *
                                            100
                                        ).toFixed(2)
                                }}%</span>
                            <span class="font-weight-medium pa-1 rounded" v-else
                                style="background-color: #ffeeee; color: #e12626">{{
                                        (
                                            (totals.Exchange / totals.Net_Value) *
                                            100
                                        ).toFixed(2)
                                }}%</span>
                        </span>
                    </td>
                </tr>
            </template>
            <template v-if="usernodata" v-slot:no-data>
                <v-col class="mx-auto pa-15">
                    <v-img class="align-self-stretch mx-auto" width="100px"
                        :src="require('@/assets/no data folder.svg')" />
                    <h4>No data available</h4>
                </v-col>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                    <div class="row">
                        <div class="col-auto">
                        </div>
                        <div class="col">
                            <h6>Details</h6>
                            ... {{ item.MarketCap }}
                        </div>
                    </div>
                </td>
            </template>
        </v-data-table>
    </div>
</template>
  
<script>
import { apiurl } from "../../apiUrl";

import axios from "axios";
export default {
    data() {
        return {
            page: 1,
            pageCount: 0,
            expanded: [],
            togglers: {},

            usertotaldata: true,
            usernodata: false,
            clientids: [],
            holdingdetails: [],
            sumget: [],
            dataget: [],
            FullName: "",
            clsval: [],
            closepnl: 0,
            printsumget: [],
            printsumgetloader: true,
            tokengetdata: [],
            tableDataFinal: [],
            tradedetails: [],
            emailDatafetch: []
        };
    },
    methods: {
        checkClientSync() {
            var gauthclientseassion = localStorage.clientsessionstore
            // var gauthclientseassion = localStorage.clientsessionstore
            var getTokendata = localStorage.getItem("getTokendata")
            var email = localStorage.getItem('decryptedstoredData')
            var data = JSON.stringify({
                email: email,
            });

            var config = {
                method: "post",
                url: `${apiurl}/syncEmailDetails`,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': gauthclientseassion
                },
                data: data,
            };
            var axiosThis = this
            var axiosthis = this
            axios(config)
                .then(function (response) {
                    console.warn("&&&e", response);
                    axiosthis.emailDatafetch = response.data.Data;
                    console.log("clientIds", response.data.msg)
                    if (response.data.msg == 'No Email Sync data available for User') {
                        axiosthis.printsumgetloader = false;
                        axiosthis.usernodata = true;
                    }
                    for (var d = 0; d < axiosthis.emailDatafetch.length; d++) {
                        var clientIds = axiosthis.emailDatafetch[d].clientID;
                        // console.log("clientIds", axiosthis.emailDatafetch[d].clientID)
                        axiosthis.clientids.push(axiosthis.emailDatafetch[d].clientID);
                        var data = JSON.stringify({
                            clientid: clientIds,
                        });

                        var config = {
                            method: "post",
                            url: `${apiurl}/holdingDetails`,
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': gauthclientseassion
                            },
                            data: data,
                        };

                        axios(config)
                            .then(function (response) {
                                // console.log("response", response);

                                var holdingdetails = response.data.data;
                                console.log("response", holdingdetails);
                                if (response.data.msg == "No Data Available") {
                                    axiosThis.usernodata = true;
                                    axiosThis.printsumgetloader = false;
                                    axiosThis.usertotaldata = false;
                                } else {
                                    // console.log("holdingdetails", axiosThis.holdingdetails);
                                    for (var i = 0; i < holdingdetails.length; i++) {
                                        axiosThis.sumget.push(holdingdetails[i].Summary);
                                        console.log("sumget--1", axiosThis.sumget);

                                    }
                                }
                                // console.log("sumget--1", axiosThis.sumget);
                            })
                            .catch(function (error) {
                                console.log(error);
                            });
                    }

                    setTimeout(function () {
                        // var axiosThis = this
                        console.log("ppp", axiosThis.sumget)
                        for (var l = 0; l < axiosThis.sumget.length; l++) {
                            var sumdetails = axiosThis.sumget[l];
                            console.log(l, "sumget--1", axiosThis.sumget[l].FullName);

                            if (sumdetails.Quantity > 0) {
                                // console.log("sumget--1",l, axiosThis.sumget[l].FullName);

                                // console.log("sundd", sumdetails);
                                sumdetails.Avg_Price = Math.abs(sumdetails.Avg_Price.toFixed(2));
                                sumdetails.Net_Value = Math.abs(sumdetails.Net_Value.toFixed(2));

                                // console.log("tokk", axiosThis.tokengetdata);
                                axiosThis.printsumget.push(sumdetails);
                                var dataa = JSON.stringify({
                                    userId: "ZP00172",
                                    scripList: "nse_cm|212,nse_cm|" + sumdetails.token,
                                });

                                var tokenconfig = {
                                    method: "post",
                                    url: "https://www.zebull.in/rest/MobullService/api/marketWatch/scripsMW",
                                    headers: {
                                        Authorization: `Bearer ZP00172 ${getTokendata}`,
                                        "Content-Type": "application/json",
                                    },
                                    data: dataa,
                                };

                                axios(tokenconfig)
                                    .then(function (response) {
                                        console.log("MARKETwatch", response.data[1]);
                                        axiosThis.tokengetdata.push(response.data[1]);
                                    })
                                    .catch(function (error) {
                                        console.log(error);
                                    });
                            }
                            if (sumdetails.Quantity == 0) {
                                axiosThis.usernodata = true;
                                axiosThis.printsumgetloader = false;
                            }
                        }
                        // console.log("printsumget", axiosThis.printsumget);
                        setTimeout(function () {

                            for (var o = 0; o < axiosThis.printsumget.length; o++) {
                                console.log("loc", axiosThis.printsumget, "settimeout", axiosThis.tokengetdata)

                                for (var w = 0; w < axiosThis.printsumget.length; w++) {
                                    console.log("|", w);
                                    if (
                                        axiosThis.printsumget[o].Symbol ==
                                        axiosThis.tokengetdata[w].symbolname && axiosThis.printsumget[o].Client_Code == axiosThis.printsumget[w].Client_Code
                                    ) {
                                        console.log(
                                            axiosThis.printsumget[o].Symbol,
                                            "|",
                                            axiosThis.tokengetdata[w].symbolname
                                        );

                                        axiosThis.printsumget[o].sector = Number(
                                            axiosThis.tokengetdata[w].ltp
                                        );
                                        axiosThis.printsumget[o].BuyOrSell = Number(
                                            (
                                                axiosThis.printsumget[o].Quantity *
                                                axiosThis.printsumget[o].sector
                                            ).toFixed(2)
                                        );
                                        axiosThis.printsumget[o].Exchange = Number(
                                            (
                                                axiosThis.printsumget[o].BuyOrSell -
                                                axiosThis.printsumget[o].Net_Value
                                            ).toFixed(2)
                                        );
                                        axiosThis.printsumget[o].MarketCap = Number(
                                            (
                                                (axiosThis.printsumget[o].Exchange /
                                                    axiosThis.printsumget[o].Net_Value) *
                                                100
                                            ).toFixed(2)
                                        );
                                        axiosThis.printsumget[o]["close"] = Number(
                                            axiosThis.tokengetdata[w].close
                                        );
                                        axiosThis.printsumget[o]["pnlval"] = (axiosThis.printsumget[o].sector - axiosThis.tokengetdata[w].close) * axiosThis.printsumget[o].Quantity;
                                        //   // console.log(axiosThis.tokengetdata[o], "tokengetdata")
                                        axiosThis.tableDataFinal.push(axiosThis.printsumget[w]);
                                        // console.log(axiosThis.tableDataFinal[o], "tokengetdata")
                                        if (axiosThis.tableDataFinal.length > 0) {
                                            axiosThis.printsumgetloader = false;
                                        }
                                    }
                                }
                            }
                        }, 1500);

                        console.log("printsumget", axiosThis.printsumget);
                        //code goes here
                    }, 5000);
                    console.log("dspemailaccount", axiosthis.dspemailaccount);
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        subTotal(items, column) {
            console.warn(items, column);

            return items.reduce((sum, item) => sum + item[column], 0).toFixed(2);
        },
        toggleAll() {
            Object.keys(this.$refs).forEach(k => {
                //console.log(this.$refs[k])
                this.$refs[k].$el.click()
            })
        },
        closeAll() {
            Object.keys(this.$refs).forEach(k => {
                console.log(this.$refs[k])
                if (this.$refs[k] && this.$refs[k].$attrs['data-open']) {
                    this.$refs[k].$el.click()
                }
            })
        },
        openAll() {
            Object.keys(this.$refs).forEach(k => {
                if (this.$refs[k] && !this.$refs[k].$attrs['data-open']) {
                    this.$refs[k].$el.click()
                }
            })
        }
    },

    mounted() {
        this.checkClientSync()
        //var userBrokerClientId = localStorage.getItem("client-id");
        // var userBrokerClientId = ["ZVK0106", "ZE1A57", "ZE1A40"]
        // var axiosThis = this;
    },
    computed: {
        totals() {
            const totals = this.tableDataFinal.reduce(
                (acc, d) => {
                    acc.Quantity += d.Quantity;
                    acc.Avg_Price += d.Avg_Price;
                    acc.Net_Value += d.Net_Value;
                    acc.sector += d.sector;
                    acc.close += d.close;
                    acc.BuyOrSell += d.BuyOrSell;
                    acc.Exchange += d.Exchange;
                    acc.pnlval += d.pnlval;
                    return acc;
                },
                {
                    Quantity: 0,
                    Avg_Price: 0.00,
                    Net_Value: 0.00,
                    sector: 0.00,
                    BuyOrSell: 0.00,
                    Exchange: 0.00,
                    pnlval: 0.00,
                    none: "",
                }
            );
            totals.Avg_Price = totals.Avg_Price.toFixed(2);
            totals.Net_Value = totals.Net_Value.toFixed(2);
            totals.sector = totals.sector.toFixed(2);
            totals.BuyOrSell = totals.BuyOrSell.toFixed(2);
            totals.Exchange = totals.Exchange.toFixed(2);
            totals.pnlval = totals.pnlval.toFixed(2);
            return totals;
        },
        cliidhead() {
            return [
                {
                    text: "Company",
                    value: "FullName",
                    align: "left",
                    filter: (f) => {
                        return (f + "")
                            .toUpperCase()
                            .includes(this["FullName"].toUpperCase());
                    },
                },
                { text: "Company", value: "names", align: "left" },
                { text: "Qty", value: "Quantity", align: "right" },
                { text: "Buy avg.", value: "Avg_Price", align: "right" },
                { text: "LTP", value: "sector", align: "right" },
                { text: "Buy value", value: "Net_Value", align: "right" },
                { text: "Present value", value: "BuyOrSell", align: "right" },
                { text: "P&L", value: "Exchange", align: "right" },
                { text: "P&L change", value: "MarketCap", align: "right" },
            ];
        },
    },
};
</script>