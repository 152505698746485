<template>
  <div>
    <div>
      <p v-if="coractdata">({{ fromdate }}) to ({{ todate }})</p>
      <v-card class="pa-4 elevation-0 rounded-lg" outlined>
        <div v-if="coractloader" class="py-10 px-16">
          <p class="pa-16 pb-0 text-center">Getting your Profit & Loss</p>
          <v-progress-linear
            class="mb-12"
            color="primary"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </div>
        <div v-if="coractdata">
          <v-row no-gutters>
            <v-col v-for="months in monthname" :key="months" class="p-0 m-0 d-flex justify-center">
        {{months.date }}
                </v-col>
                </v-row>
                
          <v-row no-gutters>
            <v-col v-for="indata in monthcal" :key="indata">
              <div v-for="n in indata" :key="n" outlined class="p-0 m-0 ">
                <div v-if="n.count > 0">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-card
                        v-bind="attrs"
                        v-on="on"
                        width="4px"
                        class="colums"
                        color="#34A8539a"
                        style="margin: 2px; padding: 6px"
                        outlined
                        tile
                      >
                      </v-card>
                    </template>
                    <p class="mb-0 white--text">
                      <span class="font-weight-bold"
                        >{{ n.count }} ₹ Profit </span
                      >on
                      {{
                        new Date(n.date).toLocaleDateString("en-us", {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })
                      }}
                    </p>
                  </v-tooltip>
                </div>

                <div v-else-if="n.count < 0">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-card
                        v-bind="attrs"
                        v-on="on"
                        width="4px"
                        class="colums"
                        color="#e126269a"
                        style="margin: 2px; padding: 6px"
                        outlined
                        tile
                      >
                      </v-card>
                    </template>
                    <p class="mb-0 white--text">
                      <span class="font-weight-bold">{{ n.count }} ₹ Loss </span
                      >on
                      {{
                        new Date(n.date).toLocaleDateString("en-us", {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })
                      }}
                    </p>
                  </v-tooltip>
                </div>

                <div v-else>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-card
                        v-bind="attrs"
                        v-on="on"
                        width="4px"
                        color="#E8EBED"
                        class="colums"
                        style="margin: 2px; padding: 6px"
                        outlined
                        tile
                      >
                      </v-card>
                    </template>
                    <p class="mb-0 white--text">
                      <span class="font-weight-bold">No Profit & Loss </span> on
                      {{
                        new Date(n.date).toLocaleDateString("en-us", {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })
                      }}
                    </p>
                  </v-tooltip>
                </div>
              </div>
            </v-col>
            <!-- </div> -->
          </v-row>
          <v-row>
            <v-col cols="9">

            </v-col>
            
            <v-col cols="3">
            <p class="colums">Loss</p>
            <v-card
                        v-bind="attrs"
                        v-on="on"
                        width="4px"
                        color="#E8EBED"
                        class="colums"
                        style="margin: 2px; padding: 6px"
                        outlined
                        tile
                      >
                      </v-card>
                      <v-card
                        v-bind="attrs"
                        v-on="on"
                        width="4px"
                        class="colums"
                        color="#e126269a"
                        style="margin: 2px; padding: 6px"
                        outlined
                        tile
                      >
                      </v-card>
                      <v-card
                        v-bind="attrs"
                        v-on="on"
                        width="4px"
                        class="colums"
                        color="#34A8539a"
                        style="margin: 2px; padding: 6px"
                        outlined
                        tile
                      >
                      </v-card>
            <p>More</p>
          </v-col>
          </v-row>
        </div>

        <div v-if="nocoractdata" class="py-10">
          <p class="pa-16 text-center">No Corporate actions found</p>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import { apiurl } from "../apiUrl";
import axios from "axios";
export default {
  data() {
    return {
      fromdate: "",
      todate: "",
      datesummary: [],
      dataget: [],
      tradedetails: [],
      tokengetdata: [],
      tableDataFinal: [],
      date: [],
      pldates: [],
      ltpcal: [],
      calendarcount: [],
      token: [],
      fullyear: [],
      years: [],
      coractdata: false,
      nocoractdata: false,
      coractloader: true,
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      // days:12,
      monthcal: [],
      monthname :[],

    };
  },

  mounted() {
    var getTokendata = localStorage.getItem("getTokendata");
    this.name = localStorage.decryptedstoredname;
    var axiosThis = this;
    var data = JSON.stringify({
      clientid: "ZVK0106",
      // clientid: "ZE1A57",
    });
    var gauthclientseassion = localStorage.clientsessionstore;
    var config = {
      method: "post",
      url: `${apiurl}/mtmsummary`,
      headers: {
        "Content-Type": "application/json",
        Authorization: gauthclientseassion,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log("response", response.data);
        axiosThis.datesummary = response.data;
        if (response.data.msg == "No Data Available") {
          axiosThis.coractdata = false;
          axiosThis.nocoractdata = true;
          axiosThis.coractloader = false;
        }
        // console.log("datepl", axiosThis.datesummary);
        for (var i = 0; i < axiosThis.datesummary.length; i++) {
          axiosThis.dataget.push(axiosThis.datesummary[i]);
        }
        console.log(axiosThis.dataget);
        for (var l = 0; l < axiosThis.dataget.length; l++) {
          var trddtl_s1s = axiosThis.dataget[l];
          console.log("sec index", trddtl_s1s);
          for (var [key, value] of Object.entries(trddtl_s1s)) {
            console.log("object", trddtl_s1s);
            axiosThis.pldates.push([key]);
            console.log("key", axiosThis.pldates);
            var tk = value;
            console.log("tk", tk);
            for (var x in tk) {
              var trddtl_s2 = tk[x];
              console.log("allday", trddtl_s2);

              if (trddtl_s2.token != "") {
                axiosThis.token.push("nse_cm" + "|" + trddtl_s2.token);
                axiosThis.tradedetails.push(trddtl_s2);
              } else {
                //
              }
            }
          }
        }
        console.log(
          "tokens api",
          axiosThis.token.filter((v, i, a) => a.indexOf(v) === i)
        );

        var tokes = axiosThis.token.filter((v, i, a) => a.indexOf(v) === i);
        var token = tokes.join();
        console.log("tokes", token);
        var data = JSON.stringify({
          userId: "ZP00172",
          scripList: token,
        });

        var tokenconfig = {
          method: "post",
          url: "https://www.zebull.in/rest/MobullService/api/marketWatch/scripsMW",
          headers: {
            Authorization: `Bearer ZP00172 ${getTokendata}`,
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios(tokenconfig)
          .then(function (response) {
            console.log("dataget2", response);
            var datas = {};

            for (var l in response.data) {
              datas = response.data[l];
              console.log(datas.token, datas);
              axiosThis.tokengetdata.push(datas);
              console.log("trddtl_s3.token sa", axiosThis.tokengetdata.token);
            }
          })
          .catch(function (error) {
            console.log(error);
          });

        setTimeout(function () {
          for (var o = 0; o < axiosThis.tradedetails.length; o++) {
            for (var w = 0; w < axiosThis.tradedetails.length; w++) {
              for (var x in axiosThis.tokengetdata) {
                var tokens = axiosThis.tokengetdata[x].token;
                // console.log("valeus",tokens)
                var ltps = axiosThis.tokengetdata[x].ltp;

                if (axiosThis.tradedetails[o].token == tokens) {
                  axiosThis.tradedetails[o]["Ltp"] = Number(ltps);
                  axiosThis.tradedetails[o]["Net_rate"] =
                    axiosThis.tradedetails[o].Net_Value /
                    axiosThis.tradedetails[o].Quantity;
                  axiosThis.tradedetails[o]["Mtm"] =
                    (axiosThis.tradedetails[o].Ltp -
                      axiosThis.tradedetails[o].Net_rate) *
                    axiosThis.tradedetails[o].Quantity;
                }
              }
            }
            var datedatas1 = axiosThis.tradedetails[o];
            axiosThis.tableDataFinal.push(datedatas1);

            var short = 0;
            if (axiosThis.tradedetails[o].Quantity > 0) {
              short =
                axiosThis.tradedetails[o].Ltp -
                axiosThis.tradedetails[o].Net_Price;
            } else if (axiosThis.tradedetails[o].Quantity < 0) {
              short =
                axiosThis.tradedetails[o].Net_Price -
                axiosThis.tradedetails[o].Ltp;
            } else if (axiosThis.tradedetails[o].Quantity == 0) {
              var sellvalue =
                axiosThis.tradedetails[o].Sell_Quantity *
                axiosThis.tradedetails[o].Sell_Price;
              var Buyvalue =
                axiosThis.tradedetails[o].Buy_Quantity *
                axiosThis.tradedetails[o].Buy_Price;
              short = sellvalue - Buyvalue;
            }
            console.log("short", short, axiosThis.tradedetails[o].Trade_Date);
            axiosThis.date.push({
              date:
                datedatas1.Trade_Date.slice(-4) +
                "-" +
                datedatas1.Trade_Date.slice(3, 5) +
                "-" +
                datedatas1.Trade_Date.slice(0, 2),
              count: short,
            });
          }
          var removesamedate = Object.values(
            axiosThis.date.reduce((object, item) => {
              var key = item.date;
              if (!object[key]) {
                object[key] = Object.assign(item);
              } else {
                object[key].count += Object.assign(item.count);
              }
              return object;
            }, {})
          );
          console.log("date1", removesamedate);
          for (var m in removesamedate) {
            var countcal = removesamedate[m].count;
            var datecal = removesamedate[m].date;
            axiosThis.ltpcal.push({ date: datecal, count: countcal.toFixed(2) });
            var newdates = axiosThis.ltpcal.sort(function (a, b) {
              return new Date(a.date) - new Date(b.date);
            });

            axiosThis.calendarcount.push({
              date: datecal,
              count: countcal.toFixed(2),
            });
          }
          var first = [];
          for (var n = 0; n < newdates.length; n++) {
            first.push(newdates[n].date);
          }
          var firstdate = first[0];
          var lastdate = first.slice(-1).join();
          console.log("sort lenght", firstdate, lastdate);
          var y = new Date(lastdate).getFullYear();
          axiosThis.years.push(y);

          // var mon = new Date(lastdate).getMonth();
          var startdateformet = new Date(y, 0, 1);
          var Dateformet = new Date(y, 11, 31);
          var startmonth =
            new Date(startdateformet).getFullYear() +
            "-" +
            (1 + new Date(startdateformet).getMonth()) +
            "-" +
            new Date(startdateformet).getDate();
          var endDate =
            new Date(Dateformet).getFullYear() +
            "-" +
            (1 +
              new Date(Dateformet).getMonth() +
              "-" +
              new Date(Dateformet).getDate());
          // console.log("sort x", startmonth, endDate)
          var monthdate = axiosThis.dateRange(startmonth, endDate);

          axiosThis.fromdate = new Date(startmonth).toLocaleDateString(
            "en-us",
            { year: "numeric", month: "short", day: "numeric" }
          );
          axiosThis.todate = new Date(endDate).toLocaleDateString("en-us", {
            year: "numeric",
            month: "short",
            day: "numeric",
          });
          console.log("sort a", monthdate);
          var years=[]
          for (var k in monthdate) {
            axiosThis.calendarcount.push({
              date: new Date(monthdate[k]).toLocaleDateString("en-CA"),
              count: "",
            });
            years.push({ date:new Date(monthdate[k]).toLocaleDateString("en-us", {
            month: "short",
           }) })
           axiosThis.monthname = years.filter((v,i,a)=>a.findIndex(v2=>(v2.date===v.date))===i)
          }
          console.log("month in the year",axiosThis.monthname)
          
          console.log("colors", axiosThis.calendarcount, axiosThis.ltpcal);
          var update = Object.values(
            axiosThis.calendarcount.reduce((obj, item) => {
              var keys = item.date;
              if (!obj[keys]) {
                obj[keys] = Object.assign(item);
              } else {
                obj[keys].count += item.count;
              }
              return obj;
            }, {})
          );
          console.log("date2", update);
          var updates = update.sort(function (a, b) {
            return new Date(a.date) - new Date(b.date);
          });
          axiosThis.fullyear.push(updates);
          console.log("365 days p", axiosThis.fullyear);

          var ref = {};
          var res = updates.reduce(function (arr1, o) {
            var m = new Date(o.date).getMonth();
            if (!(m in ref)) {
              ref[m] = arr1.length;
              arr1.push([]);
            }
            arr1[ref[m]].push(o);
            return arr1;
          }, []);
          axiosThis.monthcal = res;
          console.log("filter month", res, );
          if (axiosThis.fullyear[0].length > 0) {
            axiosThis.coractdata = true;
            axiosThis.coractloader = false;
            axiosThis.nocoractdata = false;
          }
        }, 1000);
      })
      .catch(function (error) {
        console.log(error);
      });
  },

  methods: {
    dateRange(startDate, endDate, steps = 1) {
      const dateArray = [];
      let currentDate = new Date(startDate);
      while (currentDate <= new Date(endDate)) {
        dateArray.push(new Date(currentDate).toLocaleDateString("en-CA"));
        currentDate.setUTCDate(currentDate.getUTCDate() + steps);
      }
      return dateArray;
    },
  },
};
</script>
<style lang="scss">
.colums {
  position: static;
  float: left;
}
</style>