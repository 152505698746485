<template>
    <v-app>
        <v-container class="pa-0 pa-sm-3 pa-md-3">
            <div class="pt-16 pa-6 pa-sm-4 pa-md-4">
                <div>
                    <p  class="lstctntit headline mb-1">Tax-loss
                        harvesting FY (2022-2023)
                    </p>
                    <p  class="body-1" color="#878B93">Check your
                        tax-loss harvesting opportunity</p>
                </div>

                <div class="pt-4">
                    <v-card color="#FFFAF6" outlined class="elevation-0 rounded-lg pa-4 pa-md-6">
                        <v-row class="mb-4">
                            <v-col cols="12" md="6">
                                <p class="body-1" style="color: #FF8D24; font-weight: 500;">Short term tax-loss
                                    harvesting
                                    opportunity</p>
                                <span class="ctnbtl headline"
                                    style="line-height:28px; color: #505359; ">25,875</span>
                            </v-col>
                            <v-col cols="12" md="6">
                                <p class="body-1" style="color: #FF8D24; font-weight: 500;">Long term tax-loss
                                    harvesting
                                    opportunity</p>
                                <span class="ctnbtl headline"
                                    style="line-height:28px; color: #505359; ">3,32,248</span>
                            </v-col>
                        </v-row>
                        <p style="font-weight: 700;">Opportunities to reduce your tax outgo on long and short term
                            capital
                            gains (LTCG & STCG) by
                            selling securities currently on unrealized losses. <span style="color: #1877F2;"><a
                                    style="text-decoration: none;" href="">Read
                                    more</a></span></p>
                        <p>Verify the buy average price and P&L before deciding to sell. Do consult a CA.
                            While calculating long term capital gains & its harvesting opportunity, first 1L is tax-free
                            &
                            is not considered.</p>
                    </v-card>
                </div>

                <div class="pt-4">
                    <p  class="body-1 mb-1" color="#878B93">
                        Gains & Losses</p>
                    <v-card outlined class="elevation-0 rounded-lg pa-6">
                        <v-row>
                            <v-col cols="6" sm="3" md="3">
                                <p class="caption"
                                    style="color: #FF8D24; letter-spacing: 0.16em; text-transform: uppercase; ">
                                    Realized short term gains (STCG)</p>
                                <span class="ctnbtl headline"
                                    style="line-height:28px; color: #505359; ">-6588</span>
                            </v-col>
                            <v-col cols="6" sm="3" md="3">
                                <p class="caption"
                                    style="color: #FF8D24; letter-spacing: 0.16em; text-transform: uppercase; ">
                                    Unrealized short term losses (STCL)</p>
                                <span class="ctnbtl headline"
                                    style="line-height:28px; color: #505359; ">0</span>
                            </v-col>
                            <v-col cols="6" sm="3" md="3">
                                <p class="caption"
                                    style="color: #FF8D24; letter-spacing: 0.16em; text-transform: uppercase; ">
                                    Realized long term gains (LTCG)</p>
                                <span class="ctnbtl headline"
                                    style="line-height:28px; color: #505359; ">16,284</span>
                            </v-col>
                            <v-col cols="6" sm="3" md="3">
                                <p class="caption"
                                    style="color: #FF8D24; letter-spacing: 0.16em; text-transform: uppercase; ">
                                    Unrealized long term losses (LTCL)</p>
                                <span class="ctnbtl headline"
                                    style="line-height:28px; color: #505359; ">2,57,289</span>
                            </v-col>
                        </v-row>
                    </v-card>
                </div>
            </div>
        </v-container>
    </v-app>
</template>

<script>
export default {
    data() {
        return {

        };
    },
}
</script>